import Sorter from "../sorter";
import IAsyncCollection, { CountHandler, FindHandler, GetHandler } from "./iAsyncCollection";
import Key from '../key';
import HandlerCache from "../cache/handlerCache";
import Query from "@universal/types/technic/Query";
import SortRule from "@universal/types/technic/Sort";
import FilteredCollection from "./filteredCollection";
import SortedCollection from "./sortedCollection";
import { combinate, combinateQueryOrQueryWithOptimizer } from "../query";


class AsyncCollectionIterator<Type> implements AsyncIterator<Type, undefined> {
  private index: number;
  private collection: AsyncCollection<Type>;
  private items: Promise<Array<Type>>;
  private finished: boolean;

  constructor(collection: AsyncCollection<Type>) {
    this.index = -1;
    this.collection = collection;
    this.items = this.collection.toArray();
    this.finished = false;
  }

  async next(...[value]: [] | [any]): Promise<IteratorResult<Type, undefined>> {
    if(!this.finished){
      let items = await this.items;
      ++this.index;
      if(this.index >= items.length && !this.collection.allItemsLoaded()){
        await this.collection.slice(this.index, 30);
        items = await this.items;
      }
      if(this.index < items.length) {
        return {
          done: false,
          value: items[this.index]
        };
      }
      this.finished = true;
    }
    return {
      done: true,
      value: undefined
    };
  }

  async return(value?: PromiseLike<undefined> | undefined): Promise<IteratorResult<Type, undefined>> {
    this.finished = true;
    return {
      done: true,
      value: (await value) || undefined
    };
  }
}

class AsyncCollection<Type> implements IAsyncCollection<Type> {
  private _key: Key<Type>;

  private _find: FindHandler<Type>;
  
  private _get: GetHandler<Type>;

  private _count: HandlerCache<number>;

  protected _collection: FilteredCollection<Type>;

  private offsetEnd: number;

  private allLoaded: boolean;

  constructor(key: Key<Type>, query: Query<Type>, sortRule: SortRule<Type>, find: FindHandler<Type>, get: GetHandler<Type>, count: CountHandler<Type>) {
    this._key = key;

    this._find = find;
    this._get = get;
    this._count = new HandlerCache<number>(count);

    this._collection = new FilteredCollection<Type>(
      new SortedCollection<Type>(key, sortRule),
      query
    );

    this.offsetEnd = -1;
    this.allLoaded = false;
  }
  get length(): Promise<number> {
    return this._count.get(this._collection.query);
  }
  
  async has(item: Type): Promise<boolean> {
    return await this.get(item) !== undefined;
  }
  add(item: Type): void {
    if(this._collection.has(item) || this._collection.isInBound(item)){
      this._collection.add(item);
    }
  }
  addMany(items: Iterable<Type>): void {
    let toAdd = [];
    for(const item of items){
      if(this._collection.has(item) || this._collection.isInBound(item)){
        toAdd.push(item);
      }
    }
    if(!toAdd.length){
      return;
    }
    this._collection.addMany(items);
  }
  drop(item: Type): void {
    this._collection.drop(item);
  }
  dropMany(items: Iterable<Type>): void {
    this._collection.dropMany(items);
  }
  toArray(): Promise<Type[]> {
    throw new Error("Method not implemented.");
  }
  async get(object: Partial<Type>): Promise<Type> {
    let item = this._collection.get(this._key.extract(object) as Partial<Type>);
    if(item){
      return item;
    }
    return this._get(object);
  }
  async forEach(callback: (item: Type) => void): Promise<void> {
    for await(const item of this) {
      callback(item);
    }
  }
  async map<NewType>(callback: (item: Type) => NewType): Promise<NewType[]> {
    const results: NewType[] = [];
    for await(const item of this) {
      results.push(callback(item));
    }
    return results;
  }
  async slice(offset: number, limit: number): Promise<Type[]> {
    if(offset + limit >= this.offsetEnd && !this.allLoaded){
      const offsetEnd = offset + limit - 1;      
      
      const results = await this._find(
        this._collection.query,
        this._collection.sorter.sortRule,
        this.offsetEnd + 1,
        offsetEnd - this.offsetEnd
      );

      if(results.length < limit){
        this.allLoaded = true;
      }

      this._collection.addMany(results);
      this.offsetEnd = offsetEnd;
    }
    return this._collection.slice(offset, limit);
  }
  find(query: Query<Type>, sort: SortRule<Type> | Sorter<Type>): IAsyncCollection<Type> {
    return new AsyncCollection(
      this._key,
      combinateQueryOrQueryWithOptimizer("$and", query, this._collection.query),
      Sorter.create(sort).join(this._collection.sorter).sortRule,
      this._find,
      this._get,
      this._count.handler
    );
  }
  clear(): void {
    this._collection.clear();
    this.offsetEnd = -1;
  }

  allItemsLoaded(): boolean {
    return this.allLoaded;
  }

  [Symbol.asyncIterator](): AsyncIterator<Type, any, any> {
    return new AsyncCollectionIterator(this);
  }

}

export default AsyncCollection;