import { isThisHour } from "date-fns";
import React, { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import Event, { Listener } from "@uLib/event";
import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';
import SanitizeHtmlConfiguration from "@universal/types/technic/SanitizeHtmlConfiguration";
import FilterKeyStartWith from "@universal/types/technic/FilterKeyStartWith";
import Slot from "@universal/components/slot2";

import "./editor.css";


type StartHandler = (capture: Capture) => void;
type UpdateHandler = (capture: Capture, ev: KeyboardEvent) => void;
type DisposeHandler = (capture: Capture) => void;

export class Capture {
  private _editor: Editor;

  private _el: Node;

  private _triggerLength: number;

  private _start: number;

  private _end: number;

  private _startHandler: StartHandler;

  private _updateHandler: UpdateHandler;

  private _disposeHandler: DisposeHandler;

  constructor(editor: Editor, el: Node, triggerLength: number, start: number, startHandler: StartHandler, updateHandler: UpdateHandler, disposeHandler: DisposeHandler) {
    this._editor = editor;
    this._el = el;
    this._triggerLength = triggerLength;
    this._start = start;
    this._end = start;
    this._startHandler = startHandler;
    this._updateHandler = updateHandler;
    this._disposeHandler = disposeHandler;
    this.start();
  }

  get startIndex(){
    return this._start;
  }

  get endIndex(){
    return this._end;
  }

  get editor() {
    return this._editor;
  }

  get element(): Node {
    return this._el;
  }

  get text(): string {
    return this.element.textContent?.substring(this._start + this._triggerLength, this._end) || "";
  }

  createDataNode(html: string, indivisible?: boolean, className?: string, data?: any) {
    const span = document.createElement("span");
    span.innerHTML = html;
    if (className) {
      span.className = className;
    }
    if (indivisible) {
      span.setAttribute("indivisible", "true");
    }
    if (data) {
      span.setAttribute("data", JSON.stringify(data));
    }
    return span;
  }

  replace(nodes: Node[] | Node): void {    
    if(!Array.isArray(nodes)) {
      nodes = [nodes];
    }

    const selection = window.document.getSelection();
    if (!selection) {
      return;
    }
    selection.removeAllRanges();
    const newRange = document.createRange();
    newRange.setStart(this._el, this._start);
    newRange.setEnd(this._el, this._end);
    selection.addRange(newRange);
    newRange.deleteContents();

    nodes.forEach(node => {
      newRange.insertNode(node);
    });

    selection.collapseToEnd()

    this.release();
    this.editor.triggerChange();
  }

  setText(newText: string): void {
    const selection = window.document.getSelection();
    if (!selection?.rangeCount) {
      return;
    }

    const text = this._el.textContent;
    if (!text) {
      return;
    }
    this._el.textContent = text.substring(0, this._start) + newText + text.substring(this._start + this.text.length + this._triggerLength);
    const newRange = document.createRange();
    newRange.setStart(this._el, this._start + newText.length);
    newRange.setEnd(this._el, this._start + newText.length);
    selection.removeAllRanges();
    selection.addRange(newRange);
    this.editor.triggerChange();
  }

  persist() {
    this._editor.currentCapture = this;
  }

  release() {
    this._editor.currentCapture = null;
    if (this._disposeHandler) {
      this._disposeHandler(this);
    }
  }

  start() {
    this._startHandler(this);
  }

  triggerUpdate(ev: KeyboardEvent) {
    const selection = window.document.getSelection();
    if (!selection?.rangeCount) {
      return;
    }
    const range = selection.getRangeAt(0);
    if (range.endContainer !== this.element) {
      return;
    }
    this._end = range.endOffset;
    if (this._start === this._end) {
      this.release();
    }
    this._updateHandler(this, ev);
  }
}

type Trigger = {
  start: StartHandler;
  update: UpdateHandler;
  dispose: DisposeHandler;
}

export type EditorConfig = {
  triggers?: Record<string, Trigger | StartHandler>;
  events?: {
    onKeyUp?: (ev: KeyboardEvent, editor: Editor) => void;
    onKeyDown?: (ev: KeyboardEvent, editor: Editor) => void;
    onKeyPress?: (ev: KeyboardEvent, editor: Editor) => void;
    onFocus?: (ev: FocusEvent, editor: Editor) => void;
    onBlur?: (ev: FocusEvent, editor: Editor) => void;
    onChange?: (value: string, datas: any[], editor: Editor) => void;
  }[]
}


export class Editor {
  private _element: HTMLElement;

  private _last: string | undefined;

  private _config: EditorConfig;

  private _onChange: Event<[string, any[], Editor]>;

  private _onKeyDownEvent: Event<[KeyboardEvent, Editor]>;

  private _onKeyUpEvent: Event<[KeyboardEvent, Editor]>;

  private _onKeyPressEvent: Event<[KeyboardEvent, Editor]>;

  private _onFocusEvent: Event<[FocusEvent, Editor]>;

  private _onBlurEvent: Event<[FocusEvent, Editor]>;

  private _currentCapture: Capture | null;

  private _triggers: Record<string, Trigger>;

  private _configListeners: {
    onKeyUp: Listener<[KeyboardEvent, Editor]>[];
    onKeyDown: Listener<[KeyboardEvent, Editor]>[];
    onKeyPress: Listener<[KeyboardEvent, Editor]>[];
    onFocus: Listener<[FocusEvent, Editor]>[];
    onBlur: Listener<[FocusEvent, Editor]>[];
    onChange: Listener<[string, any[], Editor]>[];
  }

  constructor(element: HTMLElement, config: EditorConfig = {}) {
    this._element = element;
    this._config = config;
    this._onChange = new Event<[string, any[], Editor]>();
    this._onKeyDownEvent = new Event<[KeyboardEvent, Editor]>();
    this._onKeyUpEvent = new Event<[KeyboardEvent, Editor]>();
    this._onKeyPressEvent = new Event<[KeyboardEvent, Editor]>();
    this._onFocusEvent = new Event<[FocusEvent, Editor]>();
    this._onBlurEvent = new Event<[FocusEvent, Editor]>();

    this._currentCapture = null;
    this._triggers = {};
    this._configListeners = {
      onKeyUp: [],
      onKeyDown: [],
      onKeyPress: [],
      onFocus: [],
      onBlur: [],
      onChange: []
    };

    this.init();
  }

  get onChange() {
    return this._onChange;
  }

  get onKeyUp() {
    return this._onKeyUpEvent;
  }

  get onKeyDown() {
    return this._onKeyDownEvent;
  }

  get onKeyPress() {
    return this._onKeyPressEvent;
  }

  get onFocus() {
    return this._onFocusEvent;
  }

  get onBlur() {
    return this._onBlurEvent;
  }

  get element() {
    return this._element;
  }

  dispose() {
    this.clearCapture();

    this._configListeners.onKeyUp.forEach(listener => this._onKeyUpEvent.removeListener(listener));
    this._configListeners.onKeyDown.forEach(listener => this._onKeyDownEvent.removeListener(listener));
    this._configListeners.onKeyPress.forEach(listener => this._onKeyPressEvent.removeListener(listener));
    this._configListeners.onFocus.forEach(listener => this._onFocusEvent.removeListener(listener));
    this._configListeners.onBlur.forEach(listener => this._onBlurEvent.removeListener(listener));
    this._configListeners.onChange.forEach(listener => this._onChange.removeListener(listener));

    this._element.removeEventListener("keydown", this._triggerKeyDown);
    this._element.removeEventListener("keyup", this._triggerKeyUp);
    this._element.removeEventListener("keypress", this._triggerKeyPress);
    this._element.removeEventListener("focus", this._triggerFocus);
    this._element.removeEventListener("blur", this._triggerBlur);
  }

  init() {
    this._element.contentEditable = "true";

    this._element.addEventListener("keydown", this._triggerKeyDown);
    this._element.addEventListener("keyup", this._triggerKeyUp);
    this._element.addEventListener("keypress", this._triggerKeyPress);
    this._element.addEventListener("focus", this._triggerFocus);
    this._element.addEventListener("blur", this._triggerBlur);

    if (this._config.events) {
      this._config.events.forEach(event => {
        if (event.onKeyUp) {
          const listener = new Listener(event.onKeyUp, null);
          this._configListeners.onKeyUp.push(listener);
          this._onKeyUpEvent.addListener(listener);
        }
        if (event.onKeyDown) {
          const listener = new Listener(event.onKeyDown, null);
          this._configListeners.onKeyDown.push(listener);
          this._onKeyDownEvent.addListener(listener);
        }
        if (event.onKeyPress) {
          const listener = new Listener(event.onKeyPress, null);
          this._configListeners.onKeyPress.push(listener);
          this._onKeyPressEvent.addListener(listener);
        }
        if (event.onFocus) {
          const listener = new Listener(event.onFocus, null);
          this._configListeners.onFocus.push(listener);
          this._onFocusEvent.addListener(listener);
        }
        if (event.onBlur) {
          const listener = new Listener(event.onBlur, null);
          this._configListeners.onBlur.push(listener);
          this._onBlurEvent.addListener(listener);
        }
        if (event.onChange) {
          const listener = new Listener(event.onChange, null);
          this._configListeners.onChange.push(listener);
          this._onChange.addListener(listener);
        }
      });
    }

    if (!this._config.triggers) {
      return;
    }

    const configTriggers = this._config.triggers;

    this._triggers = Object.keys(configTriggers).reduce<Record<string, Trigger>>((triggers, key) => {
      let trigger = {} as Partial<Trigger>;
      if (configTriggers[key] instanceof Function) {
        trigger.start = configTriggers[key];
        trigger.update = () => { };
        trigger.dispose = () => { };
      } else {
        trigger = configTriggers[key];
      }
      triggers[key] = trigger as Trigger;
      return triggers;
    }, {});

  }

  hasCapture() {
    return this._currentCapture !== null;
  }

  set currentCapture(capture: Capture | null) {
    this._currentCapture = capture;
  }

  clean() {
    this.clearCapture();
    this.element.innerHTML = "";
  }

  clearCapture() {
    if (this._currentCapture) {
      this._currentCapture.release();
      this._currentCapture = null;
    }
  }

  getCurrentLast(length: number): string {
    const selection = window.document.getSelection();
    if (!selection?.rangeCount) {
      return "";
    }

    const range = selection.getRangeAt(0);

    return range.startContainer.textContent?.substring(range.startOffset - length, range.startOffset) || "";
  }

  createCapture(length: number, startHandler: StartHandler, updateHandler: UpdateHandler = () => {}, disposeHandler: DisposeHandler = () => {}): Capture | null {
    const selection = window.document.getSelection();
    if (!selection?.rangeCount) {
      return null;
    }

    const range = selection.getRangeAt(0);

    return new Capture(
      this,
      range.startContainer,
      length,
      range.startOffset - length,
      startHandler,
      updateHandler,
      disposeHandler
    );
  }

  private _triggerKeyDown = (ev: KeyboardEvent): void => {
    if (["Backspace", "Delete"].indexOf(ev.code) !== -1) {
      const selection = window.document.getSelection();
      if (!selection?.rangeCount) {
        return;
      }
      const range = selection.getRangeAt(0);

      if (range.startContainer.parentElement?.getAttribute("indivisible")) {
        range.startContainer.parentElement.removeChild(range.startContainer);
      }
      if (range.endContainer !== range.startContainer && range.endContainer.parentElement?.getAttribute("indivisible")) {
        range.endContainer.parentElement.removeChild(range.endContainer);
      }
    }
    this._onKeyDownEvent.trigger(ev, this);
    this.triggerChange();
  }

  private _triggerKeyUp = (ev: KeyboardEvent): void => {
    if (ev.key.length === 1 || ["Backspace", "Space"].indexOf(ev.key) !== -1) {
      if (this._currentCapture) {
        this._currentCapture.triggerUpdate(ev);
      } else {
        const values: Record<number, string> = {};
        const keys = Object.keys(this._triggers);
        let found = false;
        for (let i = 0; i < keys.length && !found; ++i) {
          const key = keys[i];
          if (!values[key.length]) {
            values[key.length] = this.getCurrentLast(key.length);
          }
          if (values[key.length] === key) {
            found = true;
            this.createCapture(
              key.length,
              this._triggers[key].start,
              this._triggers[key].update,
              this._triggers[key].dispose
            );
          }
        }
      }
    }
    this._onKeyUpEvent.trigger(ev, this);
    this.triggerChange();
  }

  private _triggerKeyPress = (ev: KeyboardEvent): void => {
    this._onKeyPressEvent.trigger(ev, this);
  }

  private _triggerFocus = (ev: FocusEvent) => {
    this._onFocusEvent.trigger(ev, this);
  }

  private _triggerBlur = (ev: FocusEvent) => {
    this._onBlurEvent.trigger(ev, this);
  }

  get value(): string {
    return this.element.innerHTML;
  }

  get datas(): any[] {
    const datas: any[] = [];

    const els = this._element.querySelectorAll("*[data]");
    els.forEach(el => {
      datas.push(JSON.parse(el.getAttribute("data") as string))
    });

    return datas;
  }

  triggerChange(): void {
    if (this._last !== this.element.innerHTML) {
      this._last = this.element.innerHTML;

      this._onChange.trigger(this.value, this.datas, this);
    }
  }

}

type ParamsType<EventName extends keyof FilterKeyStartWith<Editor, "on">, Params> = Editor[EventName] extends Listener<infer P>
  ? (Params extends P
    ? Params
    : never
  ) : never;

const useEditorListener = <EventName extends keyof FilterKeyStartWith<Editor, "on">, Params extends any[]>(context: { editor: Editor | null }, event: EventName, handler: ((...args: ParamsType<EventName, Params>) => void) | undefined): void => {
  React.useEffect(() => {
    const editor = context.editor;
    if (!editor || !handler) {
      return;
    }
    const listener = new Listener(handler, null) as (typeof editor[typeof event]) extends Event<infer P> ? Listener<P> : never;
    editor[event].addListener(listener);
    return () => {
      editor[event].removeListener(listener);
    }
  }, [context, handler]);
};

type EditorComponentProps = {
  config?: EditorConfig;
  onChange?: (value: string, datas: any[], editor: Editor) => void;
  onKeyUp?: (ev: KeyboardEvent, editor: Editor) => void;
  onKeyDown?: (ev: KeyboardEvent, editor: Editor) => void;
  onKeyPress?: (ev: KeyboardEvent, editor: Editor) => void;
  onFocus?: (ev: FocusEvent, editor: Editor) => void;
  onBlur?: (ev: FocusEvent, editor: Editor) => void;
  style?: React.CSSProperties;
}

type EditorComponentValueProps = {
  value: string | null;
  sanitizeConfiguration: SanitizeHtmlConfiguration<string>;
}

type EditorComponentWithValueProps = EditorComponentProps & EditorComponentValueProps;

const isEditorComponentValueProps = (props: Partial<EditorComponentWithValueProps>): props is EditorComponentValueProps => {
  return (!!props.value || props.value === null) && !!props.sanitizeConfiguration;
}

const Header = Slot<(editor: Editor) => ReactNode>();
const Footer = Slot<(editor: Editor) => ReactNode>();

type EditorComponentType = FunctionComponent<PropsWithChildren<EditorComponentProps | EditorComponentWithValueProps>> & {
  Header: typeof Header;
  Footer: typeof Footer;
};

const EditorComponent: EditorComponentType = ({ style, config = {}, onChange, onKeyDown, onKeyUp, onKeyPress, onFocus, onBlur, children, ...props }) => {
  const editorElement = React.useRef<HTMLDivElement>(null);

  const context = React.useMemo(() => ({
    editor: null as Editor | null
  }), []);

  React.useEffect(() => {
    context.editor = new Editor(editorElement.current as HTMLDivElement, config);
    return () => {
      context.editor?.dispose();
    };
  }, []);

  useEditorListener(context, "onChange", onChange);
  useEditorListener(context, "onKeyDown", onKeyDown);
  useEditorListener(context, "onKeyUp", onKeyUp);
  useEditorListener(context, "onKeyPress", onKeyPress);
  useEditorListener(context, "onFocus", onFocus);
  useEditorListener(context, "onBlur", onBlur);

  if (isEditorComponentValueProps(props)) {
    React.useEffect(() => {
      const currentValue = props.value || "";
      const currentContentValue = (editorElement.current as HTMLDivElement).innerHTML;
      if (currentValue !== currentContentValue) {
        context.editor?.clearCapture();
        (editorElement.current as HTMLDivElement).innerHTML = sanitizeHtml(currentValue, props.sanitizeConfiguration);
      }
    }, [props.value]);
  } else {
    React.useEffect(() => { }, [undefined]);
  }

  const headerPassed = Header.get(children);
  const footerPassed = Footer.get(children);

  const header = React.useMemo(() => {
    if(!headerPassed || !context.editor){
      return null;
    }
    return <div className="bs-input-editor-header">{ headerPassed(context.editor) }</div>;
  }, [headerPassed, context.editor]);

  const footer = React.useMemo(() => {
    if(!footerPassed || !context.editor){
      return null;
    }
    return <div className="bs-input-editor-footer">{ footerPassed(context.editor) }</div>;
  }, [footerPassed, context.editor]);


  return (
    <div className="bs-input-editor">
      { header }
      <div ref={editorElement} style={style} />
      { footer }
    </div>
  )
};

EditorComponent.Header = Header;
EditorComponent.Footer = Footer;

export default EditorComponent;