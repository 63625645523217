import React        from "react";
import Application  from "@uBehaviour/application";
import Scrollbar    from "@cComponents/scrollBar";
import User         from "@entities/user";
import Button       from "@cComponents/button";
import T            from "@uBehaviour/i18n";
import Data         from "@uBehaviour/data";
import Display      from "@uComponents/displayIf";
import moment       from "moment";
import _            from "lodash";
import ConnectedEditor from './editor';

import "./view.css";





class ViewComponent extends React.Component{
  constructor(props){
    super(props);
    this._editorRef       = React.createRef();
    this._scrollbar       = React.createRef();
    this._list            = React.createRef();
    this._sendComment     = this._sendComment.bind(this);
    this._onFirstLoaded   = _.debounce(this._onFirstLoaded.bind(this), 0);
    this._onUpdated       = this._onUpdated.bind(this);
  }
  _sendComment(){
    const value = this._editorRef.current.value.trim();
    if(value){
      const datas = this._editorRef.current.datas;
      const metas = datas.reduce((metas, data) => {
        if(!metas[data.type]){
          metas[data.type] = [];
        }
        metas[data.type].push(data.data);
        return metas;
      }, {});
      const notifications = metas["User"] ? metas["User"].map(u => u._id) : [];
      this.props.repository.get("Comment").repository.create({
        subject: {
          id: this.props.id,
          resource: this.props.resource
        },
        public: false,
        message: `<div>${value}</div>`,
        notifications: notifications
      }).then(() => this._editorRef.current.value = "");
    }
  }
  _onFirstLoaded(){
    if(!this._scrollbar.current) this._onFirstLoaded();
    this._scrollbar.current.position = 1;
  }
  _onUpdated(){
    if(this._scrollbar.current.position >= 0.99){
      _.delay(() => {
        this._scrollbar.current.position = 1;
      }, 0);
    }
  }
  componentDidMount(){
    this._scrollbar.current.onScroll.addListener(this);
  }
  componentWillUnmount(){
    this._scrollbar.current.onScroll.removeListener(this);
  }
  handleEvent(){
    if(this._scrollbar.current.position < 0.1){
      this._list.current.loadNext();
    }
  }
  render(){
    return (
      <div className="bs-list-comment">
        <div className="bs-comment-content">
          <Scrollbar ref={this._scrollbar}>
            <Data.List model="Comment" query={{"subject.id": this.props.id }} load={{createdBy: { avatar: true }}} pageSize={30} sort={{ createdAt: -1 }} onFirstLoaded={this._onFirstLoaded } onUpdated={this._onUpdated} ref={ this._list }>
            {comment => {
              const createdAt = moment(comment.createdAt);
              return (
                <div className={ "bs-comment " + (this.props.session.userId === comment.createdBy._id ? "bs-comment-mine" : "bs-comment-other") }>
                  <div className="bs-comment-element">  
                    <Display.If condition={this.props.session.userId !== comment.createdBy._id}>
                      <User.Avatar user={comment.createdBy} size={ 30 }/>
                    </Display.If>
                    <div>
                      <div  className="bs-comment-header">
                        <Display.If condition={this.props.session.userId !== comment.createdBy._id}>
                          <span className="bs-comment-header-creator">{ comment.createdBy.fullname }</span>
                        </Display.If>
                        <span className="bs-comment-header-datetime"><T bind={{ date: createdAt.format("DD-MM-YYYY"), time: createdAt.format("HH:mm") }}>on_at</T></span>
                      </div>
                      <div dangerouslySetInnerHTML={{__html:comment.message }} />
                    </div>
                  </div>
                </div>
              )}
            }
            </Data.List>
          </Scrollbar>
        </div>
          <div className="bs-comment-editor">
            <ConnectedEditor querySearchUserForNotify={ this.props.query } ref={this._editorRef}/>
            <div>
              <Button.Text onClick={this._sendComment}><T>send</T></Button.Text>
            </div>
          </div>
      </div>
    );
  }
}

const View = Application.forward(["repository", "session", "acl"], [], ViewComponent);

export default View;