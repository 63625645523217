class Event<Params extends [...any] = any[]> {
  private _listeners: Listener<Params>[];

  constructor() {
    this._listeners = [];
  }
  hasListener(listener: Listener<Params>){
    return this._listeners.indexOf(listener) !== -1;
  }
  addListener(listener: Listener<Params>) {
    if (!listener || !listener.handleEvent) {
      throw new Error("Listener must have a handleEvent method");
    }
    if (this.hasListener(listener)) 
      return listener;
    
    this._listeners.push(listener);
    return listener;
  }
  removeListener(listener: Listener<Params>) {
    const idx = this._listeners.indexOf(listener);
    if (idx === -1) 
      return listener;
    
    this._listeners.splice(idx, 1);
    return listener;
  }
  trigger(...args: Params) {
    this._listeners.slice().forEach(listener => {
      listener.handleEvent(...args);
    });
  }
  clear(){
    this._listeners = [];
  }
}
type HandlerType<Params extends [...any]> = (...args: Params) => void;

class Listener<Params extends [...any] = any[]> {
  private _handler: HandlerType<Params>;

  private _context: any;

  constructor(handler: HandlerType<Params>, context: any) {
    this._handler = handler;
    this._context = context;
  }
  handleEvent(...args: Params) {
    return this._handler.apply(this._context, args);
  }
}
export default Event;
export {
  Listener
};
