// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-comment-chat {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.bs-comment-chat>*{
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 0;
}

.bs-comment-chat-list {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  overflow: hidden;
}

.bs-comment-chat-newMessageButton {
  position: absolute;
  bottom: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/entities/comment/chat.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".bs-comment-chat {\n  position: relative;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.bs-comment-chat>*{\n  flex-basis: auto;\n  flex-shrink: 0;\n  flex-grow: 0;\n}\n\n.bs-comment-chat-list {\n  flex-grow: 1;\n  flex-shrink: 1;\n  flex-basis: 0;\n  overflow: hidden;\n}\n\n.bs-comment-chat-newMessageButton {\n  position: absolute;\n  bottom: 110px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
