// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-comment-message {
  display: flex;
  flex-direction: column;
}

.bs-comment-message-left {
  align-items: start;
}

.bs-comment-message-right {
  align-items: end;
}

.bs-comment-message-content {
  width: 100%;
  padding: var(--std-padding-small);
  border-radius: var(--std-radius);
}

.bs-comment-message-content-gray {
  background-color: var(--std-color-gray-light);
}

.bs-comment-message-content-orange {
  background-color: var(--std-color-orange-light);
}

.bs-comment-message-footer {
  width: max-content;
}`, "",{"version":3,"sources":["webpack://./src/entities/comment/message.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iCAAiC;EACjC,gCAAgC;AAClC;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".bs-comment-message {\n  display: flex;\n  flex-direction: column;\n}\n\n.bs-comment-message-left {\n  align-items: start;\n}\n\n.bs-comment-message-right {\n  align-items: end;\n}\n\n.bs-comment-message-content {\n  width: 100%;\n  padding: var(--std-padding-small);\n  border-radius: var(--std-radius);\n}\n\n.bs-comment-message-content-gray {\n  background-color: var(--std-color-gray-light);\n}\n\n.bs-comment-message-content-orange {\n  background-color: var(--std-color-orange-light);\n}\n\n.bs-comment-message-footer {\n  width: max-content;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
