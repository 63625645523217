
import React, { FunctionComponent }        from "react";
import Input        from "@cComponents/input";
import useRepository from "@universal/hooks/useRepository";
import UserType from "@universal/types/business/User";
import useService from "@universal/behaviour/hooks/useService";
import TooltipService from "@common/services/tooltip";
import Query from "@universal/types/technic/Query";
import { combinate } from "@universal/lib/query";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import EditorPlugin from "@cComponents/input/editor-plugin";
import Scrollbar    from "@cComponents/scrollBar";
import User         from "@entities/user";
import { Capture, Editor as EditorClass } from "@common/components/input/editor";
import SanitizeHtmlConfiguration from "@universal/types/technic/SanitizeHtmlConfiguration";
import Button from '@cComponents/button';
import T from "@universal/behaviour/i18n";

import "./editor.css";

const ButtonSend = Button.withStyle(Button.Stylized.orange.tiny.withRadius);

type EditorFooterProps = {
  editor: EditorClass;
  onChange?: (value: string, datas: any[], editor: EditorClass) => void;
};

const EditorFooter: FunctionComponent<EditorFooterProps> = ({ editor, onChange }) => {
  const triggerChange = React.useCallback(() => {
    if(!onChange){
      return;
    }
    onChange(editor.value, editor.datas, editor);
  }, [editor, onChange]);

  return (
    <div className="bs-comments-editor-send">
      <ButtonSend onClick={ triggerChange }><T>comments_editor_send</T></ButtonSend>
    </div>
  );
};

type EditorProps = {
  querySearchUserForNotify: Query<UserType>,
  value?: string | null;
  sanitizeConfiguration: SanitizeHtmlConfiguration<string>;
  onChange?: (value: string, datas: any[], editor: EditorClass) => void;
  onFocus?: (ev: FocusEvent, editor: EditorClass) => void;
  onBlur?: (ev: FocusEvent, editor: EditorClass) => void;
}

const Editor: FunctionComponent<EditorProps> = ({ value, sanitizeConfiguration, querySearchUserForNotify, onChange, onFocus, onBlur }) => {
  const repository = useRepository<UserType, "User">("User");
  const tooltip = useService<TooltipService>("tooltip");

  const searchUserByFullname = React.useCallback((fullname: string) => {
    const fullnameQuery = fullname ? { fullname: { '$regex': fullname, '$options' : 'i' }} : null;
    
    const userQuery = combinate("$and", querySearchUserForNotify, fullnameQuery);

    return repository.find<{ avatar: Loader }>(userQuery, { fullname: 1 }, 0, 20, { avatar: true });
  }, [repository, querySearchUserForNotify]);

  const displayUserTooltipOnUserNotify = React.useCallback((users: BusinessEntity<UserType, { avatar: Loader }>[], element: Node, select: (user: UserType) => void) => {
    const component = (
      <div className="bs-tooltip-notification-list">
        <Scrollbar>
        {
          users.map(user => {
            const selectUserAndCloseTooltip = () => {
              select(user);
              tooltip.close("editor-user");
            };

            return (
              <div onClick={ selectUserAndCloseTooltip } className="bs-tooltip-notification-item">
                <User.Avatar user={user} size={ 30 }/>
                <span>{ user.fullname }</span>
              </div>
            )
          })
        }
        </Scrollbar>
      </div>
    );
    
    tooltip.add("editor-user", component, element, "top");
  }, [tooltip]);

  const closeTooltipOnUserNotify = React.useCallback((capture: Capture) => {
    tooltip.close("editor-user");
  }, [tooltip]);


  const config = React.useMemo(() => {
    let config = {};
    config = EditorPlugin.emoji(config);
    config = EditorPlugin.notification(config, searchUserByFullname, displayUserTooltipOnUserNotify, closeTooltipOnUserNotify);
    config = EditorPlugin.enterToBr(config, (ev) => ev.key === "Enter" && ev.shiftKey);
    return config;
  }, [searchUserByFullname, displayUserTooltipOnUserNotify, closeTooltipOnUserNotify]);

  const ifEnterPressTriggerChange = React.useCallback((ev: KeyboardEvent, editor: EditorClass) => {
    if(ev.key === "Enter" && !ev.shiftKey && onChange) {
      onChange(editor.value, editor.datas, editor);
    }
  }, [onChange]);

  return (
    <Input.Editor
      value={ value }
      sanitizeConfiguration={ sanitizeConfiguration }
      style={{ maxHeight: "200px" }}
      config={ config }
      onKeyDown={ ifEnterPressTriggerChange }
      onFocus={ onFocus }
      onBlur={ onBlur }
    >
      <Input.Editor.Footer>
      { (editor) => <EditorFooter editor={ editor } onChange={ onChange } /> }
      </Input.Editor.Footer>
    </Input.Editor>
  );
};

export default Editor;