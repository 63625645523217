import List from './list';
import Form from '@entities/issue/newEntry';
import { IssueDetail } from './detail';
export {
  List,
  Form,
  IssueDetail
};

export default {
  List,
  Form,
  IssueDetail
};