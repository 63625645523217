import React, { PropsWithChildren } from 'react';
import Event from '@cLib/event';
import classNames from '@uLib/classNames';

import './scrollBar.css';


type ScrollBarProps = {
  onScroll?: (scrollBar: ScrollBar) => void;
  scrollBlockClassname?: string;
  viewPortClassName?: string;
  className?: string;
  whenViewPortHeightChange?: "keepPostion" | "resetPosition";
}

interface IScroll {
  get elements(): HTMLElement[];
  get searchedPosition(): number;
  correctPosition(position: number, lastElement: HTMLElement | null): number;
}

abstract class AbstractScroll {
  static factory(event: WheelEvent, elementsClassName: string, container: HTMLDivElement, viewPort: HTMLDivElement) {
    if(event.deltaY > 0){
      return new SrollDown(elementsClassName, container, viewPort);
    } else {
      return new ScrollUp(elementsClassName, container, viewPort);
    }
  }

  protected container: HTMLDivElement;
  protected viewPort: HTMLDivElement;
  protected elementsClassName: string;

  constructor(elementsClassName: string, container: HTMLDivElement, viewPort: HTMLDivElement){
    this.elementsClassName = elementsClassName;
    this.viewPort = viewPort;
    this.container = container;
  }

  get scrollHeight(){
    return this.container.offsetHeight * 2 / 3;
  }
  
  get elements(): HTMLElement[] {
    return [...this.viewPort.querySelectorAll<HTMLElement>(`.${this.elementsClassName}`)];
  }

  correctPosition(position: number, lastElement: HTMLElement | null): number {
    if(position < 0){
      return 0;
    }

    if(position > this.viewPort.offsetHeight - this.container.offsetHeight){
      return this.viewPort.offsetHeight - this.container.offsetHeight;
    }

    return position;
  }

}

class SrollDown extends AbstractScroll implements IScroll{

  get searchedPosition(): number {
    return this.container.scrollTop + this.scrollHeight;
  }
  
  correctPosition(position: number, lastElement: HTMLElement | null): number {
    if(lastElement){
      if(position > this.searchedPosition) {
        position -= lastElement.offsetHeight;
      }

      if(position < this.container.scrollTop){
        position = this.container.scrollTop + this.scrollHeight;
      }
    }

    return super.correctPosition(position, lastElement);
  }
}

class ScrollUp extends AbstractScroll implements IScroll{
  get searchedPosition(): number {
    return this.container.scrollTop - this.scrollHeight;
  }

  correctPosition(position: number, lastElement: HTMLElement | null): number {
    if(lastElement){
      if(position < this.searchedPosition) {
        position += lastElement.offsetHeight;
      }

      if(position > this.container.scrollTop){
        position = this.container.scrollTop - this.scrollHeight;
      }
    }

    return super.correctPosition(position, lastElement);
  }
}

export default class ScrollBar extends React.Component<PropsWithChildren<ScrollBarProps>> {

  private _container: React.RefObject<HTMLDivElement>;
  private _viewport: React.RefObject<HTMLDivElement>;
  private _scrolled: Event;
  private _current: number;
  private _currentScrollTop: number;
  private _lastViewPortHeight: number;

  constructor(props: ScrollBarProps){
    super(props);
    this._onScroll  = this._onScroll.bind(this);
    this._container = React.createRef();
    this._viewport  = React.createRef();
    this._scrolled  = new Event();
    this._current   = 0;
    this._currentScrollTop = 0;
    this._lastViewPortHeight = 0;
  }

  get onScroll(){
    return this._scrolled;
  }

  get position(){
    if(!this._container.current){
      return 0;
    }
    return this._container.current.scrollTop / (this._container.current.scrollHeight - this._container.current.offsetHeight);
  }

  set position(position){
    if(!this._container.current){
      return;
    }
    this._container.current.scrollTop = position * (this._container.current.scrollHeight - this._container.current.offsetHeight);
    this._current = position;
  }

  get scrollTop(){
    if(!this._container.current){
      return 0;
    }
    return this._container.current.scrollTop;
  }

  set scrollTop(scrollTop : number){
    if(!this._container.current){
      return;
    }
    if(scrollTop > this.scrollHeight){
      scrollTop = this.scrollHeight;
    }
    if(scrollTop < 0){
      scrollTop = 0;
    }
    this._container.current.scrollTop = scrollTop;
    this._current = this.position;
  }

  get scrollBottom(){
    return this.scrollHeight - this.scrollTop;
  }

  set scrollBottom(scrollBottom: number){
    if(!this._container.current){
      return;
    }
    this.scrollTop = this.scrollHeight - scrollBottom;
  }

  get height(){
    if(!this._container.current){
      return 0;
    }
    return this._container.current.offsetHeight;
  }

  get scrollHeight(){
    if(!this._container.current){
      return 0;
    }
    return this._container.current.scrollHeight - this._container.current.offsetHeight;
  }

  get viewPortHeight(){
    if(!this._viewport.current){
      return 0;
    }
    return this._viewport.current.offsetHeight;
  }
  
  _customScroll = (e: WheelEvent) => {
    e.preventDefault();
    if(!this._container.current || !this._viewport.current || !this.props.scrollBlockClassname){
      return;
    }

    const scroll: IScroll = AbstractScroll.factory(e, this.props.scrollBlockClassname, this._container.current, this._viewport.current);    

    const elements = scroll.elements;
    const searchedPosition = scroll.searchedPosition;
    
    let newPosition = 0;
    let i = 0;
    for(; i < elements.length && newPosition <= searchedPosition; ++i){
      newPosition += elements[i].offsetHeight;
    }

    newPosition = scroll.correctPosition(newPosition, i > 0 ? elements[--i] : null);

    this._container.current.scrollTop = newPosition;

    this._onScroll();
  }

  _onScroll = () => {
    this._current = this.position;
    this._currentScrollTop = this.scrollTop;
    this._scrolled.trigger('scroll', this);
    if(this.props.onScroll){
      this.props.onScroll(this);
    }
  }

  componentDidMount(){
    this._lastViewPortHeight = this.viewPortHeight;
    
    if(!this._container.current){
      return;
    }

    if(this.props.scrollBlockClassname) {
      this._container.current.onwheel = this._customScroll;
    } else {
      this._container.current.onscroll = this._onScroll;
    }
  }

  componentWillUnmount(){
    if(!this._container.current){
      return;
    }

    this._container.current.onscroll = null;
  }
  
  componentDidUpdate(){
    if(this.props.whenViewPortHeightChange === "keepPostion" && this._lastViewPortHeight !== this.viewPortHeight && this._container.current){
      this._container.current.scrollTop = this._currentScrollTop;
      this._current = this.position;
    } else {
      this.position = this._current;
    }
    this._lastViewPortHeight = this.viewPortHeight;
  }
  
  render() {
    return (
      <div ref={ this._container } className={ classNames("bs-scrollBar").addNotEmpty(this.props.className) }>
        <div ref={ this._viewport } className={ classNames("bs-scrollbar-viewport").addNotEmpty(this.props.viewPortClassName) }>
        {
          this.props.children
        }
        </div>
      </div>
    )
  }
}