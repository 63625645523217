import React, { FunctionComponent } from 'react';
import { Dayjs } from 'dayjs';
import sanitizeHtml from 'sanitize-html';
import SanitizeHtmlConfiguration from '@universal/types/technic/SanitizeHtmlConfiguration';
import Text, { Style } from '@common/components/text';


import './message.css';

type MessageProps = {
  message: string;
  userFullname: string;
  date: Dayjs;
  disposition: "left" | "right";
  backgroundColor?: "gray" | "orange";
};

const Message: FunctionComponent<MessageProps> = ({ message, userFullname, date, disposition, backgroundColor = "gray" }) => (
  <div className={ `bs-comment-message ${ disposition === 'left' ? "bs-comment-message-left" : "bs-comment-message-right" }`}>
    <div className={ `bs-comment-message-content bs-comment-message-content-${backgroundColor}`} dangerouslySetInnerHTML={{ __html: message }} />
    <div className="bs-comment-message-footer">
    <Text style={ Style.small.gray }>{ date.format('HH:mm') } - { `@${ userFullname }` }</Text>
    </div>
  </div>
);

const withMessage: (sanitizeConfiguration: SanitizeHtmlConfiguration<string>) => FunctionComponent<MessageProps> = (sanitizeConfiguration) => {
  return (props) => {
    const { message } = props;
    
    const cleanMessage = React.useMemo(() => sanitizeHtml(message, sanitizeConfiguration), [message]);

    return <Message { ...props } message={ cleanMessage } />;
  };
}

export default withMessage;