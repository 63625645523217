import React, { FunctionComponent, PropsWithChildren } from "react";
import classNames, { type ClassNames } from '@uLib/classNames';

import './stylized.css';
import { text } from "body-parser";

const style = {
  big: true,
  tiny: true,
  small: true,
  noPadding: true,
  fluid: true,
  hoverAnimatedScale: true,
  hoverInverseColor: true,
  backgroundTransparent: true,
  backgroundOrange: true,
  backgroundOrangeTransparent: true,
  backgroundRed: true,
  backgroundWhite: true,
  backgroundWhiteTransparent: true,
  backgroundGray: true,
  borderOrange: true,
  borderWhite: true,
  borderGray: true,
  textOrange: true,
  textWhite: true,
  textGray: true,
  textBlack: true,
  textRed: true,
  textGreen: true,
  positive: true,
  negative: true,
  white: true,
  transparent: true,
  whiteTransparent: true,
  orangeTransparent: true,
  orange: true,
  red: true,
  round: true,
  alignLeft: true,
  alignRight: true,
  withRadius: true,
  notAllowed: true,
};

type StylizedComposer = {
  [Property in keyof typeof style]: Stylized;
}

type Stylized = FunctionComponent<PropsWithChildren> & StylizedComposer;

type StylizedProps = {
  disabled?: boolean;
}

const withStyle = (className?: ClassNames): Stylized => {
  const NewStylized: FunctionComponent<PropsWithChildren<StylizedProps>> = ({ disabled, children, ...props }) => (
    <button className={ classNames("bs-button-stylized").addIf("bs-button-stylized-disabled", !!disabled).addNotEmpty(className) } { ...props }>
    { children }
    </button>
  );
  return Object.keys(style).reduce((composer, key) => {
    Object.defineProperty(composer, key, {
      get: () => {
        return withStyle(classNames("bs-button-stylized-" + key).addNotEmpty(className));
      }
    });
    return composer;
  }, NewStylized) as Stylized;
};

const StylizedExtended: Stylized & { Text: Stylized, BigWhite: Stylized, BigOrange: Stylized } = Object.assign(withStyle(), {
  Text: withStyle().orange.small.withRadius,
  BigWhite: withStyle().hoverAnimatedScale.hoverInverseColor.big.white.round,
  BigOrange: withStyle().hoverAnimatedScale.hoverInverseColor.big.orange.round,
});

export default StylizedExtended;
