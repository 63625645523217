import { Service } from "@uLib/application";
import { Listener } from "@uLib/event";
import AutoDrop     from "@uLib/autoDrop";
import Pager        from "@uLib/pager";

export default class PagerService extends Service {
  constructor(){
    super("pager", ["repository"]);
    this.entityUpdatedListener  = new Listener(this.entityUpdatedListener, this);
    this._pagersByModel         = [];
    this._pagers                = new AutoDrop(pager => {
      const idx = this._pagersByModel[pager.model.name].indexOf(pager);
      this._pagersByModel[pager.model.name][idx]._destroy();
      this._pagersByModel[pager.model.name].splice(idx, 1);
    });
  }
  entityUpdatedListener(model, entity, action){
    if(this._pagersByModel[model.name]){
      this._pagersByModel[model.name].forEach(pager => {
        pager.informChange(model, entity, action);
      });
    }
  }
  async start(application){
    application.getService("repository").onEntityUpdated.addListener(this.entityUpdatedListener);
  }
  create(model, query, sort, load) {
    model = this.application.getService("repository").get(model);
    if (!sort) {
      sort = {};
    }
    if (!sort._id) {
      sort._id = 1;
    }
    const id = Pager.getId(model, query, sort, load);
    if (!this._pagers.has(id)) {
      const pager = new Pager(model, query, sort, load);
      this._pagers.create(id, pager);
      if(!this._pagersByModel[model.name]){
        this._pagersByModel[model.name] = [];
      }
      this._pagersByModel[model.name].push(pager);
    }
    return this._pagers.register(id);
  }
  register(id) {
    this._pagers.register(id);
  }
  unregister(id) {
    this._pagers.unregister(id);
  }
}