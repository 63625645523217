import React    from "react";
import Slot     from "@uComponents/slot";
import _          from "lodash";

export default class Flow extends React.Component{
  static Step = Slot();
  constructor(props){
    super(props);
    this.state = {
      contexts: [],
      step: 0
    };
  }
  clear(){
    this.setState({
      contexts: [],
      step: 0
    });
  }

  componentDidMount() {
    const stepsProps = Flow.Step.props(this, true);
    if (stepsProps[0].condition !== undefined && !stepsProps[0].condition(this.state.contexts)) {
      this.next();
    } 
  }
  
  next(context){
    this.state.contexts.push(context);
    const steps = Flow.Step.get(this, true);
    const stepsProps = Flow.Step.props(this, true);
    let step = this.state.step + 1;
    const contexts = JSON.parse(JSON.stringify(this.state.contexts));
    while(stepsProps[step].condition !== undefined && !stepsProps[step].condition(this.state.contexts.slice())) {
      contexts.push(null)
      step++;
    }
    if(step < steps.length){
      this.setState({
        contexts: contexts,
        step: step
      });
    }else{
      if(this.props.onFinish){
        this.props.onFinish(this.state.contexts);
      }
    }
  }
  previous(){
    if(this.state.step){
      this.state.contexts.pop();
      const stepsProps = Flow.Step.props(this, true);
      let step = this.state.step - 1;
      const contexts = JSON.parse(JSON.stringify(this.state.contexts));
      while(stepsProps[step].condition !== undefined && !stepsProps[step].condition(this.state.contexts.slice())) {
        if (step > 0) {
          contexts.pop()
          step--;
        } else {
          return;
        }
      }
      this.setState({
        contexts: contexts,
        step: step
      });
    }
  }
  render(){
    const steps = Flow.Step.get(this, true);
    if(this.state.step >= steps.length){
      return null;
    }
    const step  = steps[this.state.step] instanceof Function
        ? steps[this.state.step](this.state.contexts.slice(), this, this.state.step)
        : steps[this.state.step];
    if(this.props.animate){
        return this.props.animate(step);
    }else{      
      return step;
    }
  }
}