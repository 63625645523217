import { EditorConfig } from "../editor";

const enterToBr = (config: EditorConfig, isEnter: (ev: KeyboardEvent) => boolean = (ev) => ev.key === "Enter") => {
  if(!config.events) {
    config.events = [];
  }
  const events = config.events;
  events.push({
    onKeyPress: (ev, editor) => {
      if(isEnter(ev) && !editor.hasCapture()) {
        editor.createCapture(0, (capture) => {
          capture.replace(document.createElement("br"));
        });
      }
    }
  });
  return config;
}

export default enterToBr;