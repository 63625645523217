import React, { FunctionComponent } from 'react';
import Button from '@cComponents/button';
import Comment from '@universal/types/business/Comment';

import './action.css';

type Color = "orange" | "green";

type ActionDesignProps = {
  icon: string;
  color: Color;
  blink?: boolean;
};

const ActionDesign: FunctionComponent<ActionDesignProps> = ({ icon, color, blink = false }) => (
  <div className={ `bs-comment-action bs-comment-action-${color}${ blink ? ' bs-comment-action-blink' : ''}` }>
    <span className={ `fa ${ icon }` } />
  </div>
);

const ActionButton = Button.withStyle(ActionDesign);

export type ActionProps = {
  icon: string;
  color: Color;
  comment: Comment;
  withConfirmation?: boolean;
  isApplicable?: (comment: Comment) => boolean;
  action: (comment: Comment) => void;
};

const Action: FunctionComponent<ActionProps> = ({ icon, color, comment, withConfirmation, action, isApplicable = () => true }) => {
  const [waitConfirmation, setWaitConfirmation] = React.useState(false);

  const triggerAction = React.useCallback(() => {
    if(withConfirmation && waitConfirmation) {
      setWaitConfirmation(true);
      return;
    }

    if(waitConfirmation) {
      setWaitConfirmation(false);
    }
    
    action(comment);
  }, [withConfirmation, action, comment, setWaitConfirmation, waitConfirmation]);
  
  if(!isApplicable(comment)) {
    return null;
  }

  return (
    <ActionButton
      onClick={ triggerAction }
      color={ color }
      icon={ icon }
      blink={ waitConfirmation }
    />
  );
};

export default Action;