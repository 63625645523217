import { BusinessEntity } from "@universal/types/technic/Entityable";
import { Helper } from "../lib/application";
import ApiService from '../services/api';
import Valorization from '../types/business/Valorization';
import Issue from "@universal/types/business/Issue";
import _ from 'lodash';

interface ApiServiceType<P extends unknown[], R> {
  execute: (...args: P) => R;
};

type ApiStoreValorization = ApiServiceType<[Valorization], Promise<Valorization>>;
type ApiDeleteValorization = ApiServiceType<[Valorization], Promise<boolean>>;

class ValorizationHelper extends Helper {
  constructor(){
    super('valorizations', ['api']);
  }

  createOrUpdate(valorization: Valorization): Promise<Valorization>{
    const api: ApiService = this.application.getService('api');

    let service: ApiStoreValorization|null = null;

    if(valorization._id && !valorization.assignment){
      service = api.service('valorizations', 'updateOnIssue') as ApiStoreValorization;
    } else if(valorization._id && valorization.assignment){
      service = api.service('valorizations', 'updateOnAssignment') as ApiStoreValorization;
    } else if(!valorization.assignment){
      service = api.service('valorizations', 'createOnIssue') as ApiStoreValorization;
    } else{
      service = api.service('valorizations', 'createOnAssignment') as ApiStoreValorization;
    }

    return service.execute(valorization);
  }

  delete(valorization: Valorization) : Promise<boolean>{
    const api: ApiService = this.application.getService('api');

    let service: ApiDeleteValorization|null = null;

    if(!valorization.assignment){
      service = api.service('valorizations', 'deleteOnIssue') as ApiDeleteValorization;
    } else {
      service = api.service('valorizations', 'deleteOnAssignment') as ApiDeleteValorization;
    }

    return service.execute(valorization);
  }

  getUnvalorizedAssignment(issue: BusinessEntity<Issue>): Promise<string[]> {
    return this.application.getService("api").service('valorizations', 'get')
      .execute({ issue: issue._id, assignment: { $ne: null } }, null, null, 100)
      .then((valorizations: Valorization[]) => {
        const idAssignments = issue.assignments.map(assignment => assignment._id.toString());
        const valorizationsAssignmentsIds = _.uniq(valorizations.map((valorization: Valorization) => valorization.assignment));
        const unvalorizedAssignmentsIds = _.difference(idAssignments, valorizationsAssignmentsIds);

        if (!unvalorizedAssignmentsIds.length) {
          return [];
        }

        return unvalorizedAssignmentsIds.map((assignmentId: Valorization['assignment']) => {
          const assignment = issue.assignments.find((assignment) => assignmentId === assignment._id)
          return(`${issue.bsIdNumber}-${assignment.bsIdNumber}`);
        });
      });
  }
}

export default ValorizationHelper;