import React from "react";
import useService from "../../hooks/useService";
import { Listener } from '@uLib/event';
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import Pager from "@universal/lib/pager";
import PagerService from "@universal/services/pager";

interface UsePagerReturn<T, L extends Loader> {
  datas: BusinessEntity<T, L>[];
  loadNext: (pageSize?: number) => void;
  allElementsLoaded: boolean,
  reload: (pageSize?: number) => void;
  loading: boolean;
}

interface UsePagerParams<L extends Loader> {
  model: string;
  query?: Object;
  sort?: Record<string, -1|1>;
  load?: L;
  debugPager?: boolean;
  pageSize?: number;
  loadAll?: boolean;
}

function usePager<T, L extends Loader>({ model, query, sort, load = {} as L, pageSize = 10, loadAll = false, debugPager = false }: UsePagerParams<L>): UsePagerReturn<T, L> {
  const pagerService = useService<PagerService>("pager");
  
  const pager = React.useMemo<Pager>(() => {
    const pager = pagerService.create(model, query, sort, load);
    pager.debugPager = debugPager;
    return pager;
  } , [pagerService, model, JSON.stringify(query), JSON.stringify(sort), JSON.stringify(load)]);

  const [datas, setDatas] = React.useState<BusinessEntity<T, L>[]>([]);

  const listener = React.useMemo(() => new Listener(() => {
    setDatas(pager.loaded);
  }, undefined), [pager]);

  const loadAllElement = React.useCallback((pSize?: number) => {
    if(!pSize){
      pSize = pageSize;
    }
    pager.stateChange.removeListener(listener);

    pager.next(pSize).then((() => {
      if(pager.isFinishedLoading()){
        pager.stateChange.addListener(listener);
        setDatas(pager.loaded);
      } else {
        loadAllElement(pSize);
      }
    }));
  }, [pager, listener, pageSize])

  const loadNext = React.useCallback((pSize?: number) => {
    if(!pSize){
      pSize = pageSize;
    }
    pager.next(pSize);
  }, [pager]);

  const reload = React.useCallback((pSize?: number) => {
    if(!pSize){
      pSize = pageSize;
    }
    pager.reload(pSize);
  }, [pager]);

  const reloadAll =  React.useCallback((pSize?: number) => {
    pager.clear().then(() => loadAllElement(pSize));
  }, [pager, loadAll, loadAllElement]);
  
  React.useEffect(() => {
    pager.stateChange.addListener(listener);
    if(loadAll){
      loadAllElement();
    }else{
      loadNext();
    }
    return () => {
      pager.stateChange.removeListener(listener);
      pagerService.unregister(pager.id);
    }
  }, [pager, listener]);

  return {
    datas,
    loadNext: loadAll ? loadAllElement : loadNext,
    allElementsLoaded: pager.isFinishedLoading(),
    reload: loadAll ? reloadAll : reload,
    loading: pager.loading
  };
};

export default usePager;