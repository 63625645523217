/************** 
Structure I18n :
key:{
    fr:"xxx",
    en:"xxx",
    nl:"xxx"
}

Fonctionne également avec la forme : key: "chaine de cractère français" (=== pas de traduction)

**************/

const i18n = {
  "absence_formContent_deleteAbsenceValidation_accept": "Oui",
  "absence_formContent_deleteAbsenceValidation_afternoon": "l'après-midi",
  "absence_formContent_deleteAbsenceValidation_morning": "le matin",
  "absence_formContent_deleteAbsenceValidation_refuse": "Non",
  "absence_formContent_deleteAbsenceValidation_text_date": "Êtes vous certain de vouloir supprimer l'absence de %user% du %date% pour la raison : %reason% ?",
  "absence_formContent_deleteAbsenceValidation_text_period": "Êtes vous certain de vouloir supprimer l'absence de %user% du %start% au %end% pour la raison : %reason% ?",
  "absence_formContent_deleteAbsenceValidation_title": "Confirmation de suppression",
  "absence_formContent_period_afternoon": "Après-Midi",
  "absence_formContent_period_afternoon_sup1": "Uniquement le matin",
  "absence_formContent_period_morning": "Matin",
  "absence_formContent_period_morning_sup1": "À partir de l'après-midi",
  "absence_formContent_title": "Ajouter une indisponibilité à %user%",
  "access_rights": "Droits d'accès",
  "active_f": "Active",
  "active_m": "Actif",
  "add": "Ajouter",
  "add_description": "Ajouter description",
  "add_location": "Ajouter une localisation",
  "add_num_1": "er",
  "add_num_2": "d",
  "add_num_rest": "e",
  "added_buildings": "Bâtiment(s) ajouté(s)",
  "admin_issues_public_by_default": "Les signalements créés depuis le portail admin sont visibles sur le portail citoyen.",
  "admin_issues_public_by_default_subLabel": "Vous pouvez modifier cette configuration au cas par cas depuis la vue de détail d’un signalement.",
  "admin_redirectRules_management": "Cette catégorie a été paramétrée par l'équipe BetterStreet. Pour toute modification, merci de contacter le support.",
  "admin_resource_entity_bounds": "Limites géographiques de la commune",
  "admin_resource_entity_bounds_title": "Format : [ [ [lng, lat], [lng, lat] ], [ [lng, lat], [lng, lat] ] ]",
  "admin_resource_entity_identifier": "Identifiant de la structure administratice",
  "agent": "Agent",
  "agents": "Agents",
  "agents_planning": "Planning des agents",
  "allowed_admins_issues_on_public_space": "Autoriser la création de signalements sur l'espace public depuis le portail admin",
  "anonymized": "Anonymisé",
  "assignment_add": "Ajouter",
  "assignment_add_assignment": "Ajouter une assignation",
  "assignment_add_equipment": "Ajouter un équipement",
  "assignment_add_supplies": "Ajouter une fourniture",
  "assignment_assigned_to": "Assigné à",
  "assignment_assignmentTooltip_assignedTo": "Assigné à",
  "assignment_assignmentTooltip_equipments": "Équipements",
  "assignment_assignmentTooltip_period": "Planifié",
  "assignment_assignmentTooltip_supplies": "Fournitures",
  "assignment_assignmentTooltip_workInstructions": "Consignes",
  "assignment_create_supply_form_cancel": "Annuler", 
  "assignment_create_supply_form_confirm": "Valider",
  "assignment_create_supply_form_title": "Créer une nouvelle fourniture",
  "assignment_delete": "Supprimer",
  "assignment_equipments": "Équipement",
  "assignment_finish": "Terminées",
  "assignment_formContent_action_cancel": "Annuler",
  "assignment_formContent_action_submit": "Envoyer",
  "assignment_formContent_agenda_info": "Cliquez sur l’agenda pour revenir à une planification à la journée.",
  "assignment_formContent_clock_info": "Cliquez sur l’horloge pour saisir une heure de début et une heure de fin pour votre assignation.",
  "assignment_formContent_global": "Assigné à %actor% le %date%",
  "assignment_formContent_global_workInstructions": "Consignes...",
  "assignment_is_valorized_cant_delete": "Action impossible, veuillez d'abord supprimer les valorisations liées à cette assignation.",
  "assignment_item_create_at_by": "Créé %when% par %who%",
  "assignment_item_standart_formState_change_title": "Confirmation de changement de statut du bon de travail",
  "assignment_item_update_at_by": "%when% par %who%",
  "assignment_none": "Aucune assignation",
  "assignment_period_out_of_deadline": "Vous avez planifié une assignation au delà de la d'échéance, êtes vous sûr de vouloir le faire?",
  "assignment_planned": "Planifié",
  "assignment_print": "Imprimer",
  "assignment_print_modal_pdf_info": "L'impression du pdf se fera pour chaque bon de travail",
  "assignment_show_in_calendar": "Voir sur le planning",
  "assignment_supplies": "Fournitures",
  "assignment_team_absent": "Les agents de cette équipe sont absents sur cette période, souhaitez-vous tout de même procéder à la planification de l’intervention ?",
  "assignment_user_absent": "L'agent %users% est indisponible sur cette période, souhaitez-vous tout de même procéder à la planification de l’intervention ?",
  "assignment_users_absent": "Certains agents sont absents sur cette période, souhaitez-vous tout de même procéder à la planification de l’intervention ? (%users%)",
  "assignment_warningEquipment_alreadyUserOnAssignment": "%bsId% (Géré par %manager%)",
  "assignment_warningEquipment_already_used_label": "Équipement(s) déjà utilisé(s) sur cette période dans cette/ces assignation(s) :",
  "assignment_warningEquipment_areYouSure": "Êtes vous sûr de vouloir le faire ?",
  "assignment_workInstructions": "Consignes",
  "assignment_workInstructions_add": "Ajouter des consignes",
  "assignment_workInstructions_cancel": "Annuler",
  "assignment_workInstructions_save": "Sauver",
  "assignments": "Assignations",
  "assignments_list_myFilter": "Mes filtres d'assignation",
  "assignments_list_section_label_future": "À venir",
  "assignments_list_section_label_outdated": "En retard / Non terminées",
  "assignments_list_section_label_today": "Aujourd'hui",
  "assignments_list_section_label_tomorrow": "Demain",
  "assignments_list_section_label_unplanned": "Non Planifiées",
  "assignments_states_commentAndValorize_cancel": "Annuler",
  "assignments_states_commentAndValorize_comment": "Commentaires",
  "assignments_states_commentAndValorize_file_title": "Fichiers associés",
  "assignments_states_commentAndValorize_save": "Enregistrer",
  "assignments_states_commentAndValorize_title": "Confirmation du changement de statut d'une assignation",
  "assignments_states_commentAndValorize_valorization_subtitle": "Le signalement associé à l'assigation est marqué comme devant être valorisé",
  "assignments_states_commentAndValorize_valorization_title": "Valorisation",
  "assistance_modal_email_label": "Adresse email de contact",
  "assistance_modal_formSubmit": "Ouvrir l'assistance",
  "assistance_send_message": "Envoyer un message",
  "authentication_cta_discoverStarter_betterstreet": "Découvrez BetterStreet",
  "authentication_login_forgot_password": "Mot de passe oublié",
  "authentication_login_username": "Nom d'utilisateur",
  "authentication_login_password": "Mot de passe",
  "authentication_login_login": "Se connecter",
  "authentication_stay_logged_in": "Rester connecté",
  "authentification_datas": "Données d'authentification",
  "authentification_recoverPassword_goBack": "Retour",
  "authentification_recoverPassword_info": "Si vous utilisez un nom d'utilisateur et non une adresse email pour vous connecter, vous devez vous rapprocher d'un administrateur de votre strucutre pour qu'il vous renouvelle votre mot de passe.",
  "automatic_update_clotured_workorder_agent": "Mettre automatiquement à résolu un bon de travail clôturé par un agent",
  "backOffice_visibility": "Visible du backOffice",
  "belgium": "Belgique",
  "benefit_valuation_module": "Module de valorisation des prestations",
  "betterStreet_url": "URL BetterStreet",
  "betterStreet_url_subTitle": "Extension d'URL pour la page spécifique sur Betterstreet",
  "betterStreet_user": "Utilisateur Betterstreet",
  "bounds": "Limites",
  "building": "Bâtiment",
  "building_filter_places": "Sous bâtiment",
  "building_filter_with": "Avec",
  "building_filter_withPlaces": "Avec sous bâtiment",
  "building_filter_without": "Sans",
  "building_filter_withoutPlaces": "Sans sous bâtiment",
  "building_form_tags": "Étiquettes",
  "building_import_extracted_no_buildings": "Aucun bâtiment dans le fichier",
  "building_import_extracted_success": "%length% bâtiment(s) extrait(s) avec succès",
  "building_import_extraction_error": "Erreur à la ligne %row% lors de l'extraction : %message%",
  "building_import_file_end": "Vous avez atteint la fin du fichier.",
  "building_import_label": "Selection du fichier de bâtiments (export csv simple du fichier client des bâtiments avec séparateur \";\")",
  "building_logs_address": "Changement de l'adresse",
  "building_logs_disabled": "Passage à l'état désactivé",
  "building_logs_enabled": "Passage à l'état activé",
  "building_logs_externalLink_add": "Ajout d'un lien externe",
  "building_logs_externalLink_remove": "Supression du lien externe",
  "building_logs_externalLink_update": "Mise à jour du lien externe",
  "building_logs_name": "Changement de nom",
  "building_type": "Type de bâtiment",
  "buildins_import": "Import multiple de bâtiments",
  "calendar": "Calendrier",
  "calendar_form_print_description": "Description",
  "calendar_form_print_description_compressed": "Partielle",
  "calendar_form_print_description_uncompressed": "Intégrale",
  "calendar_form_print_printType": "Type d'impression",
  "calendar_form_print_states": "Statut (tous par défaut)",
  "calendar_form_print_teams": "Équipes (toutes par défaut)",
  "calendar_form_print_types": "Types (tous par défaut)",
  "calendar_link_copied_documentation": "documentation",
  "calendar_link_copied_successful": "Le lien a été copié avec succès. Pour plus d'informations sur son implémentation, merci de vous référer à la %documentation%.",
  "campaign_form_country_belgium": "Belgique",
  "campaign_form_country_france": "France",
  "campaign_form_country_luxemburg": "Luxembourg",
  "campaign_form_label_country": "Pays",
  "campaign_form_label_options_id": "Identifiant TypeForm",
  "campaign_form_label_period": "Période",
  "campaign_form_label_roles": "Roles",
  "campaign_form_label_title": "Titre",
  "campaign_form_label_type": "Type",
  "campaign_form_role_admin": "Administrateur",
  "campaign_form_role_agent": "Agent",
  "campaign_form_role_buildingIssuer": "Créateur sur bâtiment",
  "campaign_form_role_buildingViewer": "Consultant bâtiment",
  "campaign_form_role_categoryManager": "Gestionnaire de catégorie",
  "campaign_form_role_equipmentIssuer": "Créateur sur équipment",
  "campaign_form_role_equipmentViewer": "Consultant équipement",
  "campaign_form_role_manager": "Manger",
  "campaign_form_role_publicSpaceIssuer": "Créateur sur espace public",
  "campaign_form_role_publicSpaceViewer": "Consultant espace public",
  "campaign_form_role_statistician": "Accès Statistique",
  "campaign_form_role_teller": "Guichet",
  "campaign_form_role_viewer": "Consultant",
  "campaign_form_title": "Campagne de sondage",
  "campaign_manager_askAgain_accept": "Répondre à ce sondage plus tard",
  "campaign_manager_askAgain_decline": "Ne plus me proposer ce sondage",
  "campaign_manager_askAgain_label": "Les sondages nous permettent de mieux vous connaître et ainsi de pouvoir améliorer le logiciel Betterstreet pour qu'ils correspondent à vos besoins. Que souhaitez-vous ?",
  "cartographic_external_link": "Lien externe",
  "categories": "Catégories",
  "categories_type": "Type de catégories",
  "category": "Catégorie",
  "category_deadlineRules": "Règles de date d'échéance",
  "category_default_followers": "Suiveurs par défaut",
  "category_filter_citizen_visibility": "Visible par les citoyens",
  "category_filter_citizen_visibility_invisible": "Non visible par les citoyens",
  "category_filter_citizen_visibility_visible": "Visible par les citoyens",
  "category_filter_follower": "Suiveur",
  "category_filter_manager": "Responsable",
  "category_filters_default_valorization": "Valorisation des signalements obligatoire par défaut",
  "category_filters_default_valorization_no": "Valorisation des signalements obligatoire",
  "category_filters_default_valorization_yes": "Valorisation des signalements non obligatoire",
  "category_followers": "Suiveurs",
  "category_followers_select": "Selection des suiveurs par défaut",
  "category_form_deadlineReminder": "Délai de rappel avant date d'échéance",
  "category_form_deadlineReminder_input_days": "Jours",
  "category_form_deadlineReminderEmailAlert": "Envoyer une alerte par email au responsable de catégorie à l’approche de l'échéance",
  "category_form_defaultDeadline": "Date d'échéance automatique",
  "category_form_default_transfer_list_title": "Sélectionnez le transfert automatique",
  "category_form_default_valorization": "Valorisation des signalements obligatoire par défaut",
  "category_form_manager": "Responsable",
  "category_form_publicByDefault_warning": "Passer une catégorie à « Privé » change la visibilité de tous les signalements associés sur le portail citoyen. Cette action est définitive pour les signalements existants. Ils resteront visibles uniquement pour leur créateur sur le portail citoyen.",
  "category_form_tags": "Étiquettes",
  "category_form_type_readOnlyInfo": "La modification du type d'une catégorie est impossible afin de conserver la cohérence entre son type et celui des signalements associés.",
  "category_item_default_transfer": "Transfert automatique",
  "category_item_disabled_label": "Active",
  "category_item_disabled_no": "Non",
  "category_item_disabled_yes": "Oui",
  "category_list_display_bulkForm": "Multistructure",
  "category_list_display_selectManager": "Responsables",
  "category_logs_disabled": "Passage à l'état désactivé",
  "category_logs_enabled": "Passage à l'état activé",
  "category_logs_followers_add": "Ajout de suiveur par défaut",
  "category_logs_followers_drop": "Suppression de suiveur par défaut",
  "category_logs_label_en": "Modification du nom anglais",
  "category_logs_label_fr": "Modification du nom français",
  "category_logs_label_nl": "Modification du nom holandais",
  "category_logs_redirectRule": "Modification de la règle de redirection",
  "category_logs_redirectRule_manager_from": "De responsable: %manager%",
  "category_logs_redirectRule_manager_to": "Vers responsable: %manager%",
  "category_logs_redirectRule_monotenant_from": "De la categorie \"%category%\" de la structure \"%tenant%\"",
  "category_logs_redirectRule_monotenant_to": "Vers la categorie \"%category%\" de la structure \"%tenant%\"",
  "category_logs_redirectRule_multitenant_from": "De mode multistructure",
  "category_logs_redirectRule_multitenant_to": "Vers mode multistructure",
  "category_logs_type": "Modification du type",
  "category_manager": "Gestionnaire de categorie",
  "category_multiStructure_form_label_citizen": "Ouvert au citoyen?",
  "category_multiStructure_form_label_dependantTenant": "Structures dépendantes",
  "category_multiStructure_form_label_label": "Nom",
  "category_multiStructure_form_label_manager": "Responsable",
  "category_multiStructure_form_label_tenantManager": "Structure manageant",
  "category_multiStructure_form_label_type": "Type",
  "category_multiStructure_form_title": "Création de catégorie sur plusieurs structures",
  "category_form_citizen_visibility": "Catégories disponibles pour les signalements citoyens",
  "category_form_public_by_default": "Signalements publics par défaut",
  "citizens": "Citoyens",
  "client_not": "Pas client",
  "close": "Fermer",
  "code": "Code",
  "comment": "Commentaire",
  "comment_title": "Rédaction d'un commentaire",
  "comment_warning": "Pour rester en accord avec les directives du RGPD merci de ne pas saisir de données personnelles dans votre réponse. Ce message sera, en effet, visible de tous. La saisie du nom du citoyen, de son adresse e-mail ou d’un numéro de téléphone est donc à bannir.\nPour lui répondre en privé vous pouvez utiliser le bouton « Contacter par email »",
  "comments": "Discussion",
  "comments_chat_newMessage": "Nouveau message",
  "comments_editor_send": "Envoyer",
  "commune_admin": "Administrateur de la commune",
  "communication_kit_filename": "Kit de communication BetterStreet",
  "communication_kit_label": "Kit de communication",
  "complementary_informations": "Informations complémentaires",
  "components_select_cancel": "Annuler",
  "components_select_search": "Chercher",
  "components_select_valid": "Valider",
  "configuration": "Configuration",
  "configuration_form_label_adminMobileVersion": "Numéro de la version mobile admin",
  "configuration_form_label_cguVersion": "Version mentions légales / CGU",
  "configuration_form_label_citizenMobileVersion": "Numéro de la version mobile citizen",
  "connected_user_backToSuperAdmin": "Revenir à la connexion superAdmin",
  "connected_user_loginAs": "Se connecter en tant que",
  "connection_as": "Se connecter en tant que...",
  "contact": "Contact",
  "contacts": "Contacts",
  "cost": "Coût",
  "country": "Pays",
  "created_at_f": "Créée le",
  "created_at_m": "Créé le",
  "date_display": "Le %date%",
  "default": "Défaut",
  "delete": "Supprimer",
  "deleted": "Supprimé",
  "description": "Description",
  "dutch": "Néerlandais",
  "email": "Mail",
  "emailValidated": "Email validé",
  "email_address": "Adresse e-mail",
  "emails": "Mails",
  "emails_content": "Contenu des mails",
  "emails_fieldGroup_requirements": "Un e-mail minimum est requis",
  "emails_footer": "Pied de page des mails",
  "emails_footer_subTitle": "Pied de page spécifique ajouté sous les mails externes",
  "emails_prefix": "Préfix des mails - Nom officiel entité ou commune",
  "emails_prefix_subTitle": "Préfixe utilisé dans les mails vers citoyens, avant \"nom\". Aussi utilisé dans les messages de confirmation d'envoi en mobile ex. la ville de ou la communautés de commune de",
  "emails_signature": "Signature des mails",
  "emails_signature_subTitle": "Signature spécifique pour les mails vers l'extérieur",
  "en": "EN",
  "end_integration": "Terminer",
  "english": "Anglais",
  "enter_address": "Entrez l'adresse",
  "enter_your_search": "Entrez votre recherche",
  "entities": "Structures",
  "equipment": "Équipement",
  "equipment_encoding_label": "Encodage du fichier",
  "equipment_filter_location": "Localisation",
  "equipment_filter_with": "Avec",
  "equipment_filter_withLocation": "Avec localisation",
  "equipment_filter_without": "Sans",
  "equipment_filter_withoutLocation": "Sans localisation",
  "equipment_form_withLocation": "Localisation",
  "equipment_form_withLocation_building": "Bâtiments",
  "equipment_form_withLocation_geolocation": "Géolocalisation",
  "equipment_form_withLocation_without": "Sans",
  "equipment_import_added": "Équipement(s) ajouté(s)",
  "equipment_import_address": "Adresse dans le fichier: %address%",
  "equipment_import_cost": "Coût dans le fichier: %cost%",
  "equipment_import_end_integration": "Terminer",
  "equipment_import_extracted_no_buildings": "Aucun équipement dans le fichier",
  "equipment_import_extracted_success": "%length% équipements(s) extrait(s) avec succès",
  "equipment_import_extraction_error": "Erreur à la ligne %row% lors de l'extraction : %message%",
  "equipment_import_fetching_location": "Recherche de la localisation de l'équipement en cours...",
  "equipment_import_file_end": "Vous avez atteint la fin du fichier.",
  "equipment_import_label": "Selection du fichier d'équipements (export csv simple du fichier client des équipements avec séparateur \";\")",
  "equipment_import_name": "Nom dans le fichier : %name%",
  "equipment_import_passed": "Équipement(s) non ajouté(s)",
  "equipment_import_title": "Import multiple d'équipements",
  "equipment_import_unit": "Unité dans le fichier : %unit%",
  "equipments": "Équipements",
  "equipments_select_title": "Équipements",
  "error_incompatible_user_role": "Un utilisateur, ne se connectant pas avec un email, peut avoir uniquement le rôle d'agent",
  "extern_web_url": "URL web externe",
  "extern_web_url_subTitle": "URL externe pour lien sur page des communes liées",
  "external": "Externe",
  "external_reference": "Référence externe",
  "externalservice": "Service externe",
  "files": "Fichiers",
  "filter_deadline": "Échéance",
  "filter_deadline_custom_date": "Personnalisée",
  "filter_deadline_custom_date_stringify": "Du %start% au %end%",
  "filter_deadline_input_period_end_label": "Fin",
  "filter_deadline_input_period_start_label": "Début",
  "filter_deadline_next_7_days": "Dans les 7 prochains jours",
  "filter_deadline_outdated": "Dépassée",
  "filter_deadline_scheduled": "Doit être terminé",
  "filter_deadline_today": "Aujourd'hui",
  "filter_deadline_tomorrow": "Demain",
  "filter_manager_active": "Actif",
  "filter_manager_admin": "Administrateur",
  "filter_manager_categoryManager": "Gestionnaire de categorie(s)",
  "filter_manager_manager": "Responsable",
  "filter_manager_roles": "Rôles",
  "finished": "Terminé",
  "firstname": "Prénom",
  "forgot_password": "Mot de passe oublié",
  "fr": "FR",
  "france": "France",
  "french": "Français",
  "full": "Complet",
  "general_configuration": "Configuration générale",
  "generals": "Généralités",
  "generals_informations": "Informations générales",
  "geolocation": "Géolocalisation",
  "geolocation_subTitle": "Sélectionnez l'emprise par défaut (limites géographiques et zoom)",
  "german": "Allemand",
  "google_country": "Code pays google",
  "heritage": "Héritage",
  "home": "Accueil",
  "home_filter_header": "Filtrer les signalements par gestionnaires",
  "home_list_comingToEndSoon": "Arrivent bientôt à échéance",
  "home_list_exceededDeadline": "Echéances dépassées",
  "home_list_lastIssues": "Derniers signalements",
  "home_list_nextScheduled": "Assignations planifiées prochainement",
  "home_weather": "Météo",
  "home_weather_temperature": "%temperature%°C",
  "hour": "Heure",
  "hourly_cost": "Coût horaire",
  "hourly_cost_precise": "Coût horaire (en €)",
  "hours": "Heures",
  "identification_code": "Code d'identification",
  "identifier_type": "Type d'identification",
  "import": "Importer",
  "import_buildings_fetching_location": "Recherche de la localisation du bâtiment en cours...",
  "in_progress": "En traitement",
  "inactive_f": "Inactive",
  "inactive_m": "Inactif",
  "informations": "Informations",
  "inhabitants_number": "Nombre d'habitants",
  "insert_file_info": "Cliquez ou glissez-déposez votre fichier pour l'ajouter",
  "intern_ref_number": "Numéro de référence interne",
  "internal": "Interne",
  "internals": "Internes",
  "interventions_state": "Statut des interventions",
  "invite": "Inviter",
  "issue_assignment_assignedTo_agents": "%nbr% agents",
  "issue_assignment_assignedTo_teamAndAgent": "Équipe(s) et agent(s)",
  "issue_assignment_assignedTo_teams": "%nbr% équipes",
  "issue_assignment_warning_mustBeValorized": "Attention : La valorisation est obligatoire pour ce signalement",
  "issue_assignment_warning_mustBeValorized_instructions": "Veuillez valoriser toutes les assignations ou retirer la valorisation obligatoire avant de passer le signalement à \"résolu\".",
  "issue_assignment_warning_unvalorized_assignments": "Assignations non valorisées ",
  "issue_creation_successfull_on_client_tenant_redirect": "Votre signalement %bsId% à été redirigé vers la catégorie %categoryName% de la commune de %tenantName%", 
  "issue_detail_mandatory_valorization_label": "Valorisation obligatoire",
  "issue_detail_mandatory_valorization_value_no": "Non",
  "issue_detail_mandatory_valorization_value_yes": "Oui",
  "issue_error_target_location_dont handle_selected_category":"Cette catégorie n\’est pas géré par la commune sélectionnée",
  "issue_file_format_error": "Le fichier ne possède pas un format supporté par l'application",
  "issue_filter_agents": "Agents",
  "issue_filter_assignedToMe": "Assigné à",
  "issue_filter_assignedToMe_assigned": "Assigné à",
  "issue_filter_assignedToMe_toMe": "Moi",
  "issue_filter_assignedToMe_toMyTeam": "Mon équipe",
  "issue_filter_assigned_to": "Assigné à",
  "issue_filter_assignmentScheduled": "Période de planification",
  "issue_filter_assignmentScheduled_scheduled": "Planifié",
  "issue_filter_assignment_input_period_end_label": "Fin",
  "issue_filter_assignment_input_period_start_label": "Début",
  "issue_filter_buildings": "Bâtiments",
  "issue_filter_categories": "Catégories",
  "issue_filter_createdAt_input_period_end_label": "Fin",
  "issue_filter_createdAt_input_period_start_label": "Début",
  "issue_filter_created_at": "Date de création",
  "issue_filter_created_at_created": "Créé",
  "issue_filter_creatorType": "Type de créateur",
  "issue_filter_creatorType_citizen": "Citoyen",
  "issue_filter_creatorType_internal": "Interne",
  "issue_filter_creatorType_label": "Créateur",
  "issue_filter_creators": "Créé par",
  "issue_filter_creators_by_citizen": "Citoyen via BetterStreet",
  "issue_filter_custom_date": "Personnalisée",
  "issue_filter_default": "Voir tout",
  "issue_filter_equipments": "Équipements",
  "issue_filter_followed": "Suivi",
  "issue_filter_followed_followed": "Suivi",
  "issue_filter_followed_no": "Non",
  "issue_filter_followed_notFollowed": "Non suivi",
  "issue_filter_followed_yes": "Oui",
  "issue_filter_last_30_days": "Dans les 30 derniers jours",
  "issue_filter_last_7_days": "Dans les 7 derniers jours",
  "issue_filter_localities": "Localités",
  "issue_filter_managers": "Gestionnaires",
  "issue_filter_next7Day": "Dans les 7 prochains jours",
  "issue_filter_next_7_days": "Dans les 7 prochains jours",
  "issue_filter_postal_codes": "Codes postaux",
  "issue_filter_priority": "Priorité",
  "issue_filter_priority_1": "Normal",
  "issue_filter_priority_3": "Moyen",
  "issue_filter_priority_5": "Urgent",
  "issue_filter_recurrence": "Récurrence",
  "issue_filter_recurrence_with": "Avec",
  "issue_filter_recurrence_withRecurrence": "Avec récurrence",
  "issue_filter_recurrence_without": "Sans",
  "issue_filter_recurrence_withoutRecurrence": "Sans récurrence",
  "issue_filter_requestorType": "Type de demandeur",
  "issue_filter_requestorType_citizen": "Citoyen",
  "issue_filter_requestorType_internal": "Interne",
  "issue_filter_requestorType_label": "Demandeur",
  "issue_filter_resolvedAt_input_period_end_label": "Fin",
  "issue_filter_resolvedAt_input_period_start_label": "Début",
  "issue_filter_resolved_at": "Date de résolution",
  "issue_filter_resolved_at_resolved": "Date de résolution",
  "issue_filter_search_building": "Associé au bâtiment : %building%",
  "issue_filter_search_citizen": "Citoyen créateur : %citizen%",
  "issue_filter_search_equipment_used": "Utilise l'équipement : %equipment%",
  "issue_filter_search_equipment_concerned": "Concerne l'équipement : %equipment%",
  "issue_filter_search_used": "Utilise : ",
  "issue_filter_search_concerned": "Concerne : ",
  "issue_filter_search_freeSearch": "Contient : %searched%",
  "issue_filter_search_issue": "Signalement : %issue%",
  "issue_filter_search_userpro": "Créateur ou responsable ou assigné à : %user%",
  "issue_filter_state": "Statuts",
  "issue_filter_state_pending": "En cours",
  "issue_filter_state_finished": "Terminés",
  "issue_filter_subs_buildings": "Sous-bâtiments",
  "issue_filter_tags": "Étiquettes",
  "issue_filter_teams": "Équipes",
  "issue_filter_today": "Aujourd'hui",
  "issue_filter_tomorrow": "Demain",
  "issue_filter_transferred": "Transféré à",
  "issue_filter_type": "Type",
  "issue_filter_type_building": "Bâtiment",
  "issue_filter_type_equipment": "Équipement",
  "issue_filter_type_publicSpace": "Espace Public",
  "issue_filter_users_pro_label": "Utilisateurs pros",
  "issue_filter_valorization": "Valorisation",
  "issue_filter_valorization_with": "Avec",
  "issue_filter_valorization_withValorization": "Avec valorisation",
  "issue_filter_valorization_without": "Sans",
  "issue_filter_valorization_withoutValorization": "Sans valorisation",
  "issue_filter_visibility": "Visibilité",
  "issue_filter_visibility_private": "Privée",
  "issue_filter_visibility_public": "Publique",
  "issue_filter_workOrderStates": "Statuts",
  "issue_formContent_building": "Bâtiment",
  "issue_formContent_building_locationInfo": "Localisation précise",
  "issue_formContent_building_place": "Sous-Bâtiment",
  "issue_formContent_building_subBuilding": "Sous bâtiment",
  "issue_formContent_category": "Catégorie",
  "issue_formContent_category_default": "Choisissez une catégorie",
  "issue_formContent_description": "Description",
  "issue_formContent_equipment": "Équipement",
  "issue_formContent_files": "Fichiers",
  "issue_formContent_global": "Information générale",
  "issue_formContent_location_building": "Bâtiment",
  "issue_formContent_location_equipment": "Équipement",
  "issue_formContent_location_publicSpace": "Localisation",
  "issue_formContent_publicSpace": "Espace public",
  "issue_formContent_requestor": "Informations sur le demandeur",
  "issue_formContent_requestor_email": "Email",
  "issue_formContent_requestor_firstname": "Prénom",
  "issue_formContent_requestor_lastname": "Nom",
  "issue_formContent_requestor_phone": "Téléphone",
  "issue_formContent_requestor_type_citizen": "Citoyen",
  "issue_formContent_requestor_type_internal": "Autre interne",
  "issue_formContent_requestor_type_userPro": "Moi (%user%)",
  "issue_formContent_type": "Type de signalement",
  "issue_form_mandatory_building": "Le bâtiment est obligatoire",
  "issue_form_mandatory_equipment": "L'équipement est obligatoire",
  "issue_form_mandatory_location": "La localisation est obligatoire",
  "issue_home_deadlines_exceeded": "Echéances dépassées",
  "issue_home_last": "Derniers signalements",
  "issue_home_next_expires_soon": "Arrive bientôt à échéance",
  "issue_home_planned_next_few_days": "Planifié dans les prochains jours",
  "issue_home_select_manager": "Personnaliser ma page d'accueil",
  "issue_item_created_at_by": "Créé %when% par %who%",
  "issue_item_recurrence_monthly": "Mensuelle",
  "issue_item_recurrence_weekly": "Hebdomadaire",
  "issue_item_recurrence_yearly": "Annuelle",
  "issue_item_updated_at_by": "Mis à jour %when% par %who%",
  "issue_list_assign_groupScheduled": "Planification groupée",
  "issue_list_counter": "%nbrIssues% entrées",
  "issue_list_displayType_compact": "Liste compacte",
  "issue_list_displayType_map": "Carte",
  "issue_list_displayType_standart": "Liste",
  "issue_list_groupDeadline_deadline": "Date d'échéance",
  "issue_list_groupDeadline_modal_title": "Modifier la date d'échéance de manière groupée",
  "issue_list_groupManager_modal_title": "Modifier le gestionnaire",
  "issue_list_group_form_modal_cancel": "Annuler",
  "issue_list_group_form_modal_submit": "Sauver",
  "issue_list_myFilter": "Mes filtres de liste",
  "issue_list_export": "Exporter",
  "issue_list_printExport": "Imprimer/Exporter",
  "issue_list_printExport_exportAssignment": "Liste des assignations (csv)",
  "issue_list_printExport_exportIssue": "Liste des signalements (csv)",
  "issue_list_printExport_exportKML": "Exporter en KML",
  "issue_list_printExport_exportValorization": "Liste des valorisations (csv)",
  "issue_list_printExport_printList": "Liste",
  "issue_list_printExport_printMultipleWorkorderByPage": "Bons de travaux - Plusieurs par page",
  "issue_list_printExport_printOneWorkorderByPage": "Bons de travaux - Un par page",
  "issue_list_selectableBar_all": "Toutes",
  "issue_list_selectableBar_any": "Aucun",
  "issue_list_selectableBar_deadLine": "Date d'échéance",
  "issue_list_selectableBar_manager": "Gestionnaire",
  "issue_list_selectableBar_plane": "Planification",
  "issue_list_selectableBar_selected": "%nbrSelected% fiches sélectionnées",
  "issue_list_selectableBar_state": "Statut",
  "issue_list_sort_createdAt": "Les plus récents",
  "issue_list_sort_deadline": "Par échéances",
  "issue_list_sort_updatedAt": "Mis à jour",
  "issue_list_tooltip_info": "Cette case vous permet de sélectionner plusieurs signalements pour les traitements proposés par le bouton \"Imprimer/Exporter\"",
  "issue_logs_description": "Description",
  "issue_mustBeValorized_stateNotResolved": "Le signalement est marqué comme devant être valorisé. Cependant, toutes les assignations ne le sont pas. Vous devez d'abord toutes les valoriser (ou passer le signalement comme ne devant pas être valorisé) avant de passer le signalement à résolu.",
  "issue_scheduledFrom_input_period_end_label": "Fin",
  "issue_scheduledFrom_input_period_start_label": "Début",
  "issue_state_comment_cancel": "Annuler",
  "issue_state_comment_confirm": "Enregistrer",
  "issue_viewupdate_addfile": "Ajouter photo(s) ou PDF(s)",
  "issue_viewupdate_created_AtBy": "Créé %createdAt% par %createdBy%",
  "issue_viewupdate_deadline": "Échéance",
  "issue_viewupdate_description_cancel": "Annuler",
  "issue_viewupdate_description_save": "Sauver",
  "issue_viewupdate_file_print": "Imprimer",
  "issue_viewupdate_file_remove": "Supprimer",
  "issue_viewupdate_file_setCover": "Photo principale",
  "issue_viewupdate_manager": "Gestionnaire",
  "issue_viewupdate_priority": "Priorité",
  "issue_viewupdate_priority_1": "Normale",
  "issue_viewupdate_priority_3": "Moyenne",
  "issue_viewupdate_priority_5": "Urgente",
  "issue_viewupdate_requestor_cancel": "Annuler",
  "issue_viewupdate_requestor_contact": "Contacter par email",
  "issue_viewupdate_requestor_email": "email",
  "issue_viewupdate_requestor_firstname": "Prénom",
  "issue_viewupdate_requestor_lastname": "Nom",
  "issue_viewupdate_requestor_phoneNumber": "Téléphone",
  "issue_viewupdate_requestor_save": "Sauver",
  "issue_viewupdate_requestor_title": "Demandeur & suiveurs",
  "issue_viewupdate_requestor_type": "Type",
  "issue_viewupdate_requestor_type_bsCitizen": "Citoyen via Betterstreet",
  "issue_viewupdate_requestor_type_citizen": "Citoyen",
  "issue_viewupdate_requestor_type_internal": "Interne",
  "issue_viewupdate_requestor_type_userPro": "Créateur du signalement",
  "issue_viewupdate_requestor_update": "Modifier",
  "issue_viewupdate_scheduled": "Planifié",
  "issue_viewupdate_title_building": "Bâtiment",
  "issue_viewupdate_title_equipment": "Équipement",
  "issue_viewupdate_title_publicSpace": "Espace public",
  "issue_viewupdate_transfert": "Transférer",
  "issue_viewupdate_transfert_other": "Autre",
  "issue_viewupdate_valorization": "Valorisation",
  "issue_viewupdate_visibility": "Visibilité",
  "issue_viewupdate_visibility_private": "Privée",
  "issue_viewupdate_visibility_public": "Publique",
  "issue_viewupdate_workorder": "Bon de travail",
  "issues": "Signalements",
  "issues_bulkUpdate_error_issueStateMerged": "Le statut des siglamenents %bsIds%n'a pas pu être mise à jour car ils sont au statut fusionné, veuillez les défusionner avant de pouvoir changer leur statut",
  "issues_bulkUpdate_error_stateResolved_mustBeValorized": "Le statut des siglamenents %bsIds%n'a pas pu être mise à jour car ils sont marqués comme devant être valorisés. Cependant, toutes leurs assignations ne le sont pas. Vous devez d'abord les valoriser (ou passer les signalements comme ne devant pas être valorisés) avant de les passer à résolu.",
  "issues_location_engine": "Moteur de localisation des signalements",
  "issues_newEntry_formModal_cancel": "Annuler",
  "issues_newEntry_formModal_submit": "Valider",
  "issues_newEntry_formModal_title": "Nouvelle entrée",
  "issues_newEntry_label": "Nouvelle entrée",
  "issues_numerotation_code": "Code de numérotation des signalements",
  "issues_shortDetails_title": "Signalement %bsId% -- %category%",
  "issues_starter_form_content_send": "Envoyer",
  "issues_starter_form_content_state_form_confirm": "Confirmer",
  "issues_starter_form_content_state_form_cancel": "Annuler",
  "issues_starter_form_content_state_change_warning": "Vous allez notifier le demandeur du changement de statut",
  "issues_starter_form_content_state_change_comment_warning": "Vous allez notifier le demandeur du changement de statut et/ou du commentaire que vous avez saisi",
  "issues_starter_form_content_comment_label": "Commentaire (optionnel)",
  "issues_starter_form_content_state_label": "Nouveau statut",
  "issues_starter_form_content_issue_updated": "Le signalement a été mis à jour !",
  "issues_starter_form_content_issue_updated_description": "Le signalement a bien été mis à jour, et l’émetteur va en être informé.",
  "issues_starter_form_item_created_at_by": "Créé %when% par %who%",
  "issues_starter_form_item_updated_at_by": "Mis à jour %when% par %who%",
  "issues_starter_form_detail_print": "Imprimer",
  "starter_f_session_userConnected_unsubscribe": "Se désinscrire",
  "issues_state_comment_internal": "Commentaire interne",
  "issues_state_comment_public": "Commentaire public",
  "issues_state_comment_requestor_message": "E-mail au demandeur",
  "issues_state_comment_title": "Confirmation de changement de statut d'un signalement",
  "issues_starter_history_stateUpdated": "Nouveau statut",
  "issues_starter_history_creation": "Signalement",
  "kilogramme": "Kilogramme",
  "kilometer": "Kilomètre",
  "kilometers": "Kilomètres",
  "last_connection": "Dernière connexion",
  "lastname": "Nom",
  "latitude": "Latitude",
  "latitude_entities_tenant_form" : "Latitude",
  "learn_more": "En savoir plus",
  "limited": "Limité",
  "limited_building": "Bâtiments limités",
  "link_buildings": "Sous-bâtiments",
  "liter": "Litre",
  "location": "Localisation",
  "location_edit": "Édition de la localisation",
  "location_edit_subLabel": "Entrez une adresse ou sélectionnez un point sur la carte",
  "log_created_by_at": "Par %by% @ %at%",
  "log_new_entry": "Nouvelle entrée",
  "login_problem": "Vous rencontrez un problème ?",
  "login_contact_support_link": "Contactez notre support en cliquant %supportLink%",
  "login_contact_support_prompt": "juste ici",
  "login": "Se connecter",
  "logout": "Se déconnecter",
  "log_view_commentTitle": "Commentaire",
  "log_view_title": "Historique",
  "longitude": "Longitude",
  "longitude_entities_tenant_form" : "Longitude",
  "luxemburg": "Luxembourg",
  "mapLayer_form_label_iconUrl": "Url de l'icone",
  "mapLayer_form_label_name": "Nom",
  "mapLayer_form_label_serviceUrl": "Url du service",
  "mapLayer_form_label_transparent": "Transparent",
  "mapLayer_form_label_type": "Type",
  "mapLayer_form_transparent_false": "Non",
  "mapLayer_form_transparent_true": "Oui",
  "mapLayer_item_remove": "Supprimer la couche",
  "mapLayer_item_serviceUrl": "Url du service",
  "mapLayer_item_type": "Type",
  "mapLayer_list_filter_opaque": "Opaque",
  "mapLayer_list_filter_transparent": "Transparente",
  "mapLayer_list_filter_type": "Type",
  "map_layers": "Couches géographiques",
  "map_layers_form_new": "Nouvelle couche",
  "measure_unit": "Unité de mesure",
  "measure_unit_cost": "Coût par unité de mesure (en € TTC)",
  "medium": "Moyen",
  "members": "Membres",
  "members_fieldGroup_requirements": "Minimum un agent par équipe",
  "merged": "Fusionné",
  "mono_tenant": "Structure unique",
  "multi_tenant": "Structures multiples",
  "my_profil": "Mon profil",
  "name": "Nom",
  "new_password": "Nouveau mot de passe",
  "nl": "NL",
  "no": "Non",
  "no_rights": "Aucuns droits",
  "none": "Aucun",
  "normal": "Normal",
  "not_client_defaut_email_required": "Un email par défaut est requis pour les structures non clientes",
  "not_enough_selected": "Pas assez d'éléments sélectionnés",
  "notification_settings": "Paramètres de notification",
  "notifications_inline_message_see_more": "Voir plus",
  "notifications_item_more": " + %more% modification(s)",
  "notifications_item_reason_assignment": "Signalement assigné à vous ou votre équipe par %createdBy%",
  "notifications_item_reason_comment_citizen": "Nouveau message citoyen par %createdBy%",
  "notifications_item_reason_comment_pro": "Nouveau message interne par %createdBy%",
  "notifications_item_reason_create": "Nouveau signalement par %createdBy%",
  "notifications_item_reason_stateUpdate": "Statut passé à %state% par %createdBy%",
  "notifications_item_reason_update": "Signalement modifié par %createdBy%",
  "notifications_list_header_readAll": "Tout marquer comme lu",
  "notifications_list_header_title": "Notifications",
  "on_at": "le %date% à %time%",
  "ongoing": "En traitement",
  "open": "Ouvert",
  "open_to_citizen": "Ouvrir aux citoyens",
  "options": "Options",
  "other_mesure": "Autre mesure",
  "parameters": "Paramètres",
  "pass": "Passer",
  "passed_buildings": "Bâtiment(s) non ajouté(s)",
  "patrimony": "Équipement",
  "phone": "Téléphone",
  "photography": "Photographie",
  "picture": "Image",
  "pictures": "Images",
  "pictures_header": "Image d'en tête",
  "pictures_logo": "Logo",
  "pictures_slider": "Image de défilement",
  "piece": "Pièce",
  "planned": "Planifié",
  "planning_absence_form_input_period_end_label": "Fin",
  "planning_absence_form_input_period_start_label": "Début",
  "planning_agentHeader_teamAssignment": "Assigné à l'équipe",
  "planning_equipement_selectLabel": "Filtres",
  "planning_equipment_header": "Équipements",
  "planning_equipment_selectLabel": "Filtres",
  "planning_filter_unscheduled": "Non planifiés",
  "planning_filter_pending_workOrders": "En cours",
  "planning_filter_finished_workOrders": "Terminées",
  "planning_form_printWorkorder_printDay": "Imprimer la journée du %date%",
  "planning_form_printWorkorder_printPeriod": "Imprimer du %start% au %end%",
  "planning_form_printWorkorder_selectTeams_label": "Pour les équipes suivantes",
  "planning_form_printWorkorder_type_label": "Type d'impression",
  "planning_form_printWorkorder_type_muti": "Bons de travaux - Plusieurs par page",
  "planning_form_printWorkorder_type_one": "Bons de travaux",
  "planning_header_filterState_finished": "Terminé",
  "planning_header_filterState_ongoing": "En cours",
  "planning_header_filterState_toDo": "À faire",
  "planning_header_holidayDay": "Férié",
  "planning_header_selectPeriodDuration_days": "%nbrDays% jours",
  "planning_header_selectPeriodDuration_month": "Mois",
  "planning_header_selectPeriodDuration_week": "Semaine",
  "planning_list_nbrAssignments": "%nbrAssignments% assignation(s)",
  "planning_list_onlyPlanned": "Sans dates planifiées",
  "planning_list_seeFilters": "Voir filtres",
  "planning_list_seeList": "Voir assignations",
  "planning_myFilters": "Mes filtres de calendrier",
  "planning_print_deadline_for": "Pour le",
  "planning_selectDate_week": "Semaine %week%",
  "planning_team_absence_afternoon": "(Après-midi)",
  "planning_team_absence_cancel": "Annuler",
  "planning_team_absence_comment": "Commentaire optionnel",
  "planning_team_absence_delete": "Supprimer",
  "planning_team_absence_holidays": "Congé payé",
  "planning_team_absence_morning": "(Matin)",
  "planning_team_absence_other": "Autre",
  "planning_team_absence_period": "Période",
  "planning_team_absence_reason": "Raison",
  "planning_team_absence_recovery": "Récupération",
  "planning_team_absence_sickleave": "Congé de maladie",
  "planning_team_absence_submit": "Envoyer",
  "planning_team_copyLink": "Copier le lien Ical pour paramétrer mon calendrier",
  "planning_team_empty_createAbsence": "Ajouter une absence",
  "planning_team_empty_createAssignment": "Ajouter une assignation",
  "planning_team_linkCopied": "Le lien a été copié avec succès. Pour plus d'informations sur son implémentation, merci de vous référer à la documentation.",
  "planning_team_selectAgents": "Agents",
  "planning_team_selectEquipment": "Équipements",
  "planning_team_selectLabel": "Équipes",
  "planning_team_withoutTeam" :" Agents sans équipes",
  "planning_warningAbsence_accept": "Oui",
  "planning_warningAbsence_cancel": "Non",
  "planning_warningAbsence_label_agent": "L'agent %user% est indisponible sur cette période, souhaitez-vous tout de même procéder à la planification de l’intervention ?",
  "planning_warningAbsence_label_team": "Les agents de cette équipe sont absents sur cette période, souhaitez-vous tout de même procéder à la planification de l’intervention ?",
  "planning_warningAbsence_title": "Planification sur période d'indisponibilité",
  "planning_warningDeadline_accept": "Oui",
  "planning_warningDeadline_cancel": "Non",
  "planning_warningDeadline_label": "Vous avez planifié une assignation au delà de la date d'échéance (%deadline%), êtes vous sûr de vouloir le faire ?",
  "planning_warningDeadline_title": "Planification après la date d'échéance",
  "planning_warningEquipment_accept": "Oui",
  "planning_warningEquipment_alreadyUserOnAssignment": "%bsId% (Géré par %manager%)",
  "planning_warningEquipment_already_used_label": "Équipement(s) déjà utilisé(s) sur cette période dans cette/ces assignation(s) :",
  "planning_warningEquipment_already_used_title": "Planification sur un équipement déjà utilisé",
  "planning_warningEquipment_areYouSure": "Êtes vous sûr de vouloir le faire ?",
  "planning_warningEquipment_cancel": "Non",
  "planning_warningNotAWorkingDay_accept": "Oui",
  "planning_warningNotAWorkingDay_cancel": "Non",
  "planning_warningNotAWorkingDay_label1": "Vous avez planifié une assignation sur une ou plusieurs périodes non travaillées :",
  "planning_warningNotAWorkingDay_label2": "Êtes-vous sûr de vouloir le faire ?",
  "planning_warningNotAWorkingDay_label_oneDay": "Le %start%",
  "planning_warningNotAWorkingDay_label_period": "Du %start% au %end%",
  "planning_warningNotAWorkingDay_title": "Planification sur des périodes non travaillées",
  "pp_congratulations_title": "Félicitations ! Votre adresse email a bien été confirmée.",
  "pp_congratulations_content": "Votre commune vous remercie de contribuer à la préservation de votre environnement et l’amélioration de votre cadre de vie ! ✨",
  "previous": "Précédent",
  "print": "Imprimer",
  "print_assignment_title": "bon_travail",
  "print_assignments_title_one_per_page": "bons_travail_un_par_page",
  "print_assignments_title_several_per_page": "bons_travail_plusieurs_par_page",
  "print_issue_title": "signalement",
  "print_issues_title": "signalements",
  "print_ready": "Votre impression est prête. Il ne vous reste plus qu'à la lancer.",
  "print_wait": "Veuillez patienter pendant la génération de votre impression.",
  "print_work_order": "Imprimer le bon de travail",
  "priority_item_moyen": "Moyen",
  "priority_item_normal": "Normal",
  "priority_item_urgent": "Urgent",
  "priority_selector_priority_title": "Priorité",
  "publicSpace": "Espace public",
  "public_space": "Espace public",
  "reccurence_demo_error": "Une erreur est survenue dans le calcul de des occurrences, veuillez contacter l'équipe Betterstreet",
  "reccurence_demo_no_day_selected_error": "Aucune occurrence calculée",
  "reccurence_demo_no_occurance_generated": "Votre règle comporte une erreur lié à la configuration de votre structure (Vérifiez les jours travaillés), veuillez la modifier",
  "recover_account_by_email": "Récupérer le compte par email",
  "recurrence_add": "Ajouter",
  "recurrence_agents_disabled": " Utilisateur inactif",
  "recurrence_building_disabled": "Bâtiment inactif",
  "recurrence_category_disabled": "Catégorie inactive",
  "recurrence_copy": "Copier",
  "recurrence_created_at": "Créé %date% par %creator%",
  "recurrence_demo_createdAt": "Création le",
  "recurrence_detail_cancel_btn": "Annuler",
  "recurrence_detail_close_btn": "Fermer",
  "recurrence_detail_content_already_created": "Occurrence(s) déjà créée(s) (Trois dernières)",
  "recurrence_detail_content_deleted_recurrence": "Récurrence supprimée",
  "recurrence_detail_content_ended_recurrence": "Récurrence terminée",
  "recurrence_detail_content_general_infos": "Informations générales",
  "recurrence_detail_content_general_infos_createdAt": "Date de création : %date%",
  "recurrence_detail_content_general_infos_deleted_recurrence": "(Récurrence supprimée)",
  "recurrence_detail_content_general_infos_end": "Date de la dernière occurrence : %date%",
  "recurrence_detail_content_general_infos_end_never": "Jamais",
  "recurrence_detail_content_general_infos_ended_recurrence": "(Récurrence terminée)",
  "recurrence_detail_content_next_created": "Prochaine(s) occurrences(s)",
  "recurrence_detail_delete_btn": "Supprimer la récurrence",
  "recurrence_detail_delete_confirmed_btn": "Supprimer",
  "recurrence_detail_delete_confirmed_label": "Êtes-vous certain de vouloir supprimer cette récurrence ?",
  "recurrence_detail_modal_title": "Récurrence",
  "recurrence_equipment_disabled": "Équipement inactif",
  "recurrence_endRule_date": "La récurrence se termine le %date%",
  "recurrence_endRule_occurenceNumbers": "La récurrence se termine après la création de %number% signalement(s)",
  "recurrence_event_horizon_in_day": "Le signalement est créé %eventHorizonInDay% jour(s) avant sa date d'échéance",
  "recurrence_filters_deleted": "Supprimée",
  "recurrence_filters_ended": "Terminée",
  "recurrence_filters_inProgress": "En cours",
  "recurrence_filters_state": "État",
  "recurrence_form_assignment_allDay_no": "Non",
  "recurrence_form_assignment_allDay_yes": "Oui",
  "recurrence_form_assignment_type_agents": "Agents",
  "recurrence_form_assignment_type_team": "Équipe",
  "recurrence_form_cancel": "Annuler",
  "recurrence_form_delete": "Supprimer",
  "recurrence_form_delete_confirm_cancel": "Non",
  "recurrence_form_delete_confirm_confirm": "Oui",
  "recurrence_form_delete_confirm_label": "Confirmez vous la supression ? Cette action sera irréversible.",
  "recurrence_form_endRule": "La règle se termine : %rule%",
  "recurrence_form_endRule_alreadyPlanned": "(sur %nbrAlreadyPlanned% déjà planifiée(s))",
  "recurrence_form_endRule_date": "La récurrence se termine le %date%",
  "recurrence_form_endRule_occurenceNumbers": "La récurrence se termine après %number% occurrence(s)",
  "recurrence_form_endRule_radio_date": "Date",
  "recurrence_form_endRule_radio_never": "Jamais",
  "recurrence_form_endRule_radio_occurenceNumbers": "Nombre d'occurrence",
  "recurrence_form_execute": "Exécuter",
  "recurrence_form_issue_template": "Signalement créé",
  "recurrence_form_monthlyFixedDay": "Mensuellement jour fixe",
  "recurrence_form_monthlyRelativeDay": "Mensuellement jour relatif",
  "recurrence_form_next_occurence": "Prochaine(s) occurrence(s)",
  "recurrence_form_schedulerRule_next_occurence_lastPlannedDate": "Le calcul commencera à la date de planification du dernier signalement créé qui est le %lastPlannedDate%",
  "recurrence_form_schedulerRule_next_occurence_start": "La première occurrence sera calculée à partir du %start%",
  "recurrence_form_scheduler_rule": "Planification",
  "recurrence_form_submit": "Valider",
  "recurrence_form_type_assignment": "Avec assignation",
  "recurrence_form_type_deadline": "Date d'échéance",
  "recurrence_form_weekly": "Hebdomadaire",
  "recurrence_form_yearlyFixedDay": "Annuellement jour fixe",
  "recurrence_form_yearlyRelativeDay": "Annuellement jour relatif",
  "recurrence_label_assignment_agents": "Agent(s)",
  "recurrence_label_assignment_allDay": "Toute la journée",
  "recurrence_label_assignment_assignedTo": "Assigné à",
  "recurrence_label_assignment_endHour": "Heure de fin",
  "recurrence_label_assignment_necessariesEquipments": "Équipements",
  "recurrence_label_assignment_necessariesSupplies": "Fournitures",
  "recurrence_label_assignment_startHour": "Heure de début",
  "recurrence_label_assignment_team": "Équipe",
  "recurrence_label_assignment_type": "Type d'assignation",
  "recurrence_label_assignment_workInstructions": "Consignes",
  "recurrence_label_endRule": "Type de fin",
  "recurrence_label_plannedType": "Type de récurrence",
  "recurrence_label_schedulerRule": "Règle de récurrence",
  "recurrence_next_execution": "Prochaine exécution le %date%",
  "recurrence_schedulerRule_eventHorizonInDay": "Créer les signalements %eventHorizonInDay% jours avant la date d'échéance du signalement",
  "recurrence_schedulerRule_monthlyFixedDay": "Tous les %jump% mois, le %dayOfMonth% du mois",
  "recurrence_schedulerRule_monthlyRelativeDay": "Tous les %jump% mois, le %nth%%adNum% %dayOfWeek% du mois",
  "recurrence_schedulerRule_notWorkingDay": "Si le signalement tombe un jour non travaillé (jour non ouvrable de la semaine + jour férié)",
  "recurrence_schedulerRule_notWorkingDay_jumpNext": "Sauter à la prochaine occurrence",
  "recurrence_schedulerRule_notWorkingDay_schedule": "Planifier quand même",
  "recurrence_schedulerRule_notWorkingDay_scheduleNextWorkingDay": "Planifier le jour travaillé suivant",
  "recurrence_schedulerRule_notWorkingDay_schedulePreviousWorkingDay": "Planifier le jour travaillé précédent",
  "recurrence_schedulerRule_weekly": "Toutes les %jump% semaines, le %dayOfWeek%",
  "recurrence_schedulerRule_weekly_daySelect": "Jour(s) d'interventions",
  "recurrence_schedulerRule_weekly_weekSelect": "Effectuer l'intervention toutes les",
  "recurrence_schedulerRule_weekly_weekSelect_value": "%jump% semaine(s)",
  "recurrence_schedulerRule_yearlyFixedDay": "Tous les %jump% ans, le %dayOfMonth% %month%",
  "recurrence_schedulerRule_yearlyRelativeDay": "Tous les %jump% ans, le %nth%%adNum% %dayOfWeek% du mois de %month%",
  "recurrence_state_deleted": "Supprimée",
  "recurrence_state_ended": "Terminée",
  "recurrence_state_inProgress": "En cours",
  "recurrence_supplies_disabled": "Fourniture inactive",  
  "recurrence_team_disabled": "Équipe inactive",
  "redirect_rule": "Règle de redirection",
  "redirect_rule_cyclic_redirection": "La règle créer une redirection cyclique",
  "redirect_rule_duplicate_key_categories": "Plusieurs redirection vers la même catégorie",
  "redirect_rule_duplicate_key_tenant": "Plusieurs redirection vers une même structure",
  "redirect_rule_email": "Vers un e-mail",
  "redirect_rule_manager": "Vers un responsable",
  "redirect_rule_manager_disabled": "Impossible d'assigner un responsable inactif",
  "redirect_rule_manager_transfer_to": "Transférer automatiquement vers un contact",
  "redirect_rule_monotenant_tenant": "Vers une catégorie",
  "redirect_rule_multitenant_tenant": "Vers des catégories",
  "redirect_rule_select_category_in_tenant": "Sélection d'une catégorie dans la structure",
  "redirect_rule_select_structure": "Sélection de la structure",
  "redirect_to": "Redirigé vers",
  "redirect_to_label": "À rediriger vers",
  "rejected": "Rejeté",
  "release_bugFixed_numbers": "Bogues corrigés",
  "release_form_bugsFixed": "Bogues corrigés",
  "release_form_label_description": "Description",
  "release_form_label_releaseDate": "Date de publication",
  "release_form_label_serviceDowntime": "Temps d'arrêt du service",
  "release_form_label_version": "Version",
  "release_form_newFeatures": "Nouvelles fonctionnalités",
  "release_item_description": "Description",
  "release_item_form_label_description": "Description",
  "release_item_form_label_files": "Fichiers associés",
  "release_item_form_label_links": "Liens",
  "release_item_form_label_target_applications": "Applications",
  "release_item_form_label_target_platform": "Plateforme",
  "release_item_form_label_title": "Titre",
  "release_item_form_target_application_admin": "Admin",
  "release_item_form_target_application_citizen": "Citizen",
  "release_item_form_target_application_all": "Toutes",
  "release_item_form_target_application_starter": "Starter",
  "release_item_form_target_platform_all": "Toutes",
  "release_item_form_target_platform_mobile": "Mobile",
  "release_item_form_target_platform_web": "Web",
  "release_item_published": "Publiée",
  "release_newFeatures_numbers": "Nouvelles fonctionnalités",
  "release_not_published": "Non publiée",
  "release_publish": "Publier",
  "release_published": "Publiée",
  "releases_link_label": "Versions",
  "reset_password": "Réinitialiser le mot de passe",
  "reset_pin": "Réinitialiser le code pin",
  "resolved": "Résolu",
  "role_add_limit_building": "Ajouter une limitation sur un bâtiment",
  "role_select_tenant": "Choisir une structure",
  "save": "Sauver",
  "screen_tool_campaign": "Campagne de sondages",
  "screen_tool_test": "Outil de test",
  "select": "Sélectionner",
  "send": "Envoyer",
  "service": "Prestation externe",
  "settings": "Paramètres",
  "start_integration": "Commencer l'intégration",
  "starterActivation_alreadyActivated_title": "Il semble que vous ayez déjà un compte BetterStreet Starter...",
  "starterActivation_alreadyActivated_info": "Votre commune %tenant% a déjà un compte BetterStreet Starter associé à l'adresse email : %email%.",
  "starterActivation_alreadyActivated_button": "Aller à la page de connexion",
  "starterActivation_alreadyActivated_encounterProblem": "Vous rencontrez un problème ?",
  "starterActivation_alreadyActivated_encounterProblemSolution": "Contactez notre support en cliquant %supportLink%",
  "starterActivation_alreadyActivated_supportLinkText": "juste ici",
  "starterActivation_template_message": "Recevez, centralisez et traitez gratuitement et efficacement les demandes d’interventions de votre collectivité.",
  "starterActivation_template_betterstreetDiscovery": "Découvrir BetterStreet",
  "starterActivation_email_title": "Email",
  "starterActivation_email_advice": "Nous vous conseillons d’entrer l’email de la personnes en charge du traitement des interventions techniques de la collectivité.",
  "starterActivation_email_confirmation": "Une confirmation par email sera envoyée à cette personne, et il lui sera demandé de définir le mot de passe du compte BetterStreet Starter.",
  "starterActivation_email_acceptGeneralConditions": "J'accepte les%space%%generalConditionLink%",
  "starterActivation_email_generalConditions": "conditions générales d'utilisation",
  "starterActivation_email_validate": "Créer mon compte",
  "starterActivation_email_alreadyHaveAnAccount": "Déjà un compte ? %connect%",
  "starterActivation_email_connect": "Connectez-vous !",
  "starterActivation_almostCreated_title": "Votre compte est presque créé !",
  "starterActivation_almostCreated_informationMissing": "Il nous manque juste quelques informations.",
  "starterActivation_almostCreated_siren": "Numéro SIREN",
  "starterActivation_almostCreated_phone": "Numéro de téléphone",
  "starterActivation_almostCreated_infoTechnicContact": "Informations du contact technique",
  "starterActivation_almostCreated_advice": "Nous vous conseillons d’entrer les coordonnées de la personnes en charge du traitement des interventions techniques de la collectivité.",
  "starterActivation_almostCreated_civility": "Civilité du contact technique",
  "starterActivation_almostCreated_contactName": "Nom du contact",
  "starterActivation_almostCreated_contactFirstName": "Prénom du contact",
  "starterActivation_almostCreated_civility_female": "Madame",
  "starterActivation_almostCreated_civility_male": "Monsieur",
  "starterActivation_almostCreated_validate": "Envoyer un email de confirmation",
  "starterActivation_almostCreated_siren_luhnInvalid": "Le numéro SIREN n'est pas valide",
  "starterActivation_finalize_lastStep": "Dernière étape : Vérification de l’adresse email !",
  "starterActivation_finalize_emailSendInfo": "Afin de finaliser la création du compte et commencer à utiliser BetterStreet Starter, un email de confirmation a été envoyé à l’adresse suivante : %email%.",
  "starterActivation_finalize_confirmEmail": "Il ne reste plus qu’à vérifier l’adresse en cliquant sur le lien dans l’email, et déterminer un mot de passe en suivant les indications.",
  "starterActivation_finalize_resendMail": "Renvoyer l’email de confirmation",
  "starterActivation_finalize_resendMailInfo": "L’email n’a pas été reçu ? %resendMail%",
  "starterActivation_finalize_updateEmail": "Modifier l’adresse email",
  "starterActivation_finalize_updateEmailInfo": "Vous avez fait une erreur dans l’adresse email ? %updateEmail%",
  "starterActivation_resend_password_confirmation": "Un nouvel e-mail de validation vous a été envoyé",
  "starter_unsubscribe_confirmation_title": "Vous êtes désinscrit !",
  "starter_unsubscribe_confirmation_button_confirm": "Fermer",
  "starter_unsubscribe_confirmation_text_change_opinion": "Si vous changez d’avis, n’hésitez pas à nous contacter pour recevoir une invitation de création de compte BetterStreet Starter.",
  "starter_unsubscribe_confirmation_text_confirm": "L’adresse %mail% a bien été effacée de notre liste de diffusion pour la recommandation citoyenne, %promoted%",
  "starter_unsubscribe_confirmation_text_confirm_promoted": "votre commune ne sera plus disponible sur l’application BetterStreet.",
  "starter_unsubscribe_noCitizenEmail": "Nous ne souhaitons pas informer les citoyens par e-mail.",
  "starter_unsubscribe_noCitizenEmail_info": "Pour tenir informé les utilisateurs citoyens qui auraient fait des signalements sur votre commune, un mail automatique va leur être envoyé pour leur indiquer que vous ne souhaitez pas utiliser BetterStreet, que leur signalement ne sera donc plus visible sur l’application et qu’ils ne seront pas informés de l’évolution du traitement de celui-ci dans BetterStreet.",
  "starter_unsubscribe_reason_cancel": "Rester inscrit",
  "starter_unsubscribe_reason_checkboxValue_notInterested": "Je ne suis pas intéressé par les signalements citoyens.", 
  "starter_unsubscribe_reason_checkboxValue_alreadyHaveSTManagementProduct": "J’ai déjà un produit de gestion des interventions techniques.",
  "starter_unsubscribe_reason_checkboxValue_alreadyHaveCitizenIssuesManagementProduct": "J’ai déjà un produit de gestion des demandes citoyennes.",
  "starter_unsubscribe_reason_checkboxValue_tooManyEmail": "Je reçois trop d’emails.",
  "starter_unsubscribe_reason_confirm": "Confirmer mon choix",
  "starter_unsubscribe_reason_title": "Nous sommes navrés de vous voir partir !",
  "starter_unsubscribe_reason_text": "Dans un souci d’amélioration continue de nos produits, et de la satisfaction de nos utilisateurs, pouvez-vous nous faire part de %promoted% ?",
  "starter_unsubscribe_reason_text_promoted": "la raison de votre désinscription",
  "starter_unsubscribe_validation_text1": "Votre commune ne sera plus disponible pour la création de signalement par les citoyens depuis l'application betterstreet.",
  "starter_unsubscribe_validation_text2": "De plus les signalements existants sur votre commune seront effacés, et %promotedText%",
  "starter_unsubscribe_validation_text2_promoted":"cette suppression des signalements sera irréversible.",
  "starter_unsubscribe_validation_title": "Vous souhaitez vous désinscrire de BetterStreet ?",
  "starter_unsubscribe_validation_confirm": "Me désinscrire",
  "starter_unsubscribe_validation_cancel": "Annuler",
  "starter_cta_discoverOffer": "Découvrir les abonnements",
  "starter_cta_discoverStdExp": "Découvrez BetterStreet Standart & Expert",  
  "starter_discover_subscriptions": "Découvrir les abonnements",
  "starter_header_item_issuesList": "Signalements",
  "starter_header_item_planning": "Planning",
  "starter_header_item_stats": "Statistiques",
  "starter_header_item_newIssue": "Nouveau signalement",
  "starter_discover_goToLogin_button": "Aller à la page de connexion",
  "starter_discover_tenant_title": "Merci de sélectionner votre collectivité :",
  "starter_discover_tenant_autocomplete_placeholder": "Entrez le nom ou code postal de la collectivité",
  "starter_discover_tenant_cantFind": "Vous ne trouvez pas votre collectivité ? %contact%",
  "starter_discover_contact": "Contactez-nous !",
  "starter_discover_alreadyHaveAnAccount": "Déjà un compte ? %connect%",
  "starter_discover_connect": "Connectez-vous !",
  "starter_discover_send_invitation_email": "M'envoyer un e-mail d'invitation",
  "starter_discover_tenant_email": "E-mail de la collectivité",
  "starter_discover_tenant_email_information": "L’email d’invitation va être envoyé à l’adresse email générique de votre commune afin de nous assurer de votre identité et sécuriser la création de votre compte. Lors des étapes de création du compte Starter, vous pourrez modifier l’adresse email et définir l’utilisateur principal.",
  "starter_discover_tenant_incorrectMailInfo": "L’adresse email est incorrecte ? %contact%",
  "starter_discover_tenant_finalize_email_sent": "L’email a bien été envoyé, il ne vous reste plus qu’à créer votre compte !",
  "starter_discover_tenant_finalize_email_sent_info": "Un email d’invitation de création de compte BetterStreet Starter a bien été envoyé à l’adresse suivante : %email%.",
  "starter_discover_tenant_finalize_email_sent_instructions": "Il ne vous reste plus qu’à cliquer sur le lien dans l’email, et suivre les quelques étapes de création de compte.",
  "starter_discover_tenant_finalize_contactUs": "Contactez-nous !",
  "starter_discover_tenant_finalize_mailNotReceived": "L’email n’a pas été reçu ? %resendMail%",
  "starter_discover_tenant_finalize_resendMail": "Renvoyer l’email de confirmation",
  "starter_discover_tenant_postalCode": "Code postal",
  "starter_discover_tenant_isClient": "Il semble que vous ayez déjà un compte BetterStreet...",
  "starter_discover_tenant_isClient_info": "Votre commune a déjà un compte BetterStreet associé à l'adresse email : %mail%.",
  "starter_discover_resend_password_confirmation": "Un nouvel e-mail de validation vous a été envoyé",
  "starter_discover_login_problem": "Vous rencontrez un problème ?",
  "starter_discover_login_contact_support_link": "Contactez notre support en cliquant %supportEmail%",
  "starter_discover_login_contact_support_prompt": "juste ici ",
  "starter_issue_form_upgrade_offer_1": "Vous souhaitez créer vos propres signalements ? Gérer vos équipes ?",
  "starter_issue_form_upgrade_offer_2": "Profiter de fonctionnalités exclusives liées aux bâtiments et équipements ?",
  "starter_issues_fakeFilter_building": "Bâtiment",
  "starter_issues_fakeFilter_equipment": "Équipement",
  "starter_issues_fakeFilter_publicSpace": "Espace public",
  "starter_issues_filter_categories": "Catégories",
  "starter_issues_filter_teams": "Équipes",
  "starter_issues_filter_type": "Type",
  "starter_login_discover": "Vous ne connaissez pas notre logiciel de gestion des services techniques ?",
  "starter_promotion_interested": "Cette fonctionnalité vous intéresse ?",
  "starter_subscription_offer_ask_demo": "Demander une démonstration",
  "starter_subscription_offer_expert_option_1": "Signalement d’incidents dans les bâtiments : écoles, bibliothèques, salles polyvalentes, gymnases, …",
  "starter_subscription_offer_expert_option_2": "Inventaire des bâtiments, matériel et parc de véhicules",
  "starter_subscription_offer_expert_option_3": "Arborescence du patrimoine avec gestion des sous-bâtiments",
  "starter_subscription_offer_expert_option_4": "Suivi des entretiens périodiques du parc auto (vidange, contrôle technique, …)",
  "starter_subscription_offer_expert_option_5": "Suivi de la maintenance des bâtiments publics (Chauffage, climatisation, électricité, plomberie...)",
  "starter_subscription_offer_expert_option_6": "Suivi de la maintenance des équipements (entretien et réparation)",
  "starter_subscription_offer_expert_option_standard_features": "Fonctionnalités Standard",
  "starter_subscription_offer_standard_option_1": "Création d’interventions",
  "starter_subscription_offer_standard_option_2": "Catégorisation des signalements",
  "starter_subscription_offer_standard_option_3": "Planification et priorisation des interventions",
  "starter_subscription_offer_standard_option_4": "Attribution automatique d’une catégorie de signalement à un agent, une équipe ou un prestataire",
  "starter_subscription_offer_standard_option_5": "Planning par agent et par équipe",
  "starter_subscription_offer_standard_option_6": "Génération de bons de travail",
  "starter_subscription_offer_standard_option_7": "Interventions en mobilité grâce à l’app mobile",
  "starter_subscription_offer_standard_option_8": "Calcul des coûts d’ interventions (coût humain, matériel et fourniture)",
  "starter_subscription_offer_standard_option_9": "Traçabilité complète des interventions et historiques détaillés",
  "starter_subscription_offer_standard_option_10": "Gestion des heures travaillées et des absences",
  "starter_subscription_offer_standard_option_11": "Statistiques",
  "starter_subscription_offer_standard_option_12": "Exports",
  "starter_subscription_offer_standard_option_13": "Liaison avec le logiciel de Gestion des salles pour traiter les tâches logistiques liées aux réservations",
  "starter_subscription_offer_standard_option_starter_features": "Fonctionnalités Starter",
  "starter_subscription_offer_starter_option_1": "Signalement d’incidents sur la voie publique avec photo et localisation",
  "starter_subscription_offer_starter_option_2": "Cartographie des signalements reçus",
  "starter_subscription_offer_starter_option_3": "Mise à jour des statuts de traitement",
  "starter_subscription_offer_starter_option_4": "Notification en temps réel au demandeur",
  "starter_subscription_offer_starter_option_5": "Kit de communication digital",
  "starter_subscription_offer_subTitle": "Gestion des profils utilisateur : %profiles%",
  "starter_subscription_offer_subTitle_one_profile": "1 profil",
  "starter_subscription_offer_subTitle_unlimited": "illimité",
  "starter_subscription_offer_subTitle_starter": "Votre version actuelle",
  "starter_subscription_offer_title_starter": "BetterStreet Starter",
  "starter_subscription_offer_title_standard": "BetterStreet Standard",
  "starter_subscription_offer_title_expert": "BetterStreet Expert",
  "starter_featuresDiscovery_building_centralize_focus": "Centralisez et gérez l’ensemble des interventions liées aux bâtiments de votre collectivités",
  "starter_featuresDiscovery_building_centralize": "%focusedText% : écoles, gymnases, salles des fêtes, mairie…",
  "starter_featuresDiscovery_building_manage_subtitle": "Renseignez l’ensemble des bâtiments et sous-bâtiments.",
  "starter_featuresDiscovery_building_manage_focus": "Attribuez des catégories d’interventions, et transférez les demandes",
  "starter_featuresDiscovery_building_manage": "%focusedText% aux équipes associées ou au prestataire concerné.",
  "starter_featuresDiscovery_building_title": "Supervisez la maintenance et le suivi de vos bâtiments.",
  "starter_featuresDiscovery_building_upsale": "Passez à la formule Expert et organisez la gestion et l’entretien de l’ensemble de vos bâtiments ! 🚀",
  "starter_featuresDiscovery_category_manage_focus": "Définissez et éditez les différentes catégories d’interventions de votre territoire :",
  "starter_featuresDiscovery_category_manage": "%focusedText% Espace vert, éclairage public, collecte de déchets, chauffage...",
  "starter_featuresDiscovery_category_redirect_focus_1": "Attribuez votre catégorie à un type d’intervention :",
  "starter_featuresDiscovery_category_redirect_1": "%focusedText1% Espace public, bâtiments, ou équipements.",
  "starter_featuresDiscovery_category_redirect_focus_2": "Redirigez automatiquement les demandes d’intervention",
  "starter_featuresDiscovery_category_redirect_2": "%focusedText2% vers un responsable ou un prestataire externe.",
  "starter_featuresDiscovery_category_title": "Catégorisez vos interventions et automatisez les transferts.",
  "starter_featuresDiscovery_category_upsale": "Passez à la formule Standard ou Expert et gérez vos différentes catégories d'intervention ! 🚀",
  "starter_featuresDiscovery_equipment_geolocation": "Géolocalisez vos équipements, ou attribuez-les à un bâtiment.",
  "starter_featuresDiscovery_equipment_manage": "Suivez précisémment l’entretien %focusedText1% ainsi que de votre %focusedText2%",
  "starter_featuresDiscovery_equipment_manage_focus_1": "de l’ensemble des équipements municipaux et intercommunaux,",
  "starter_featuresDiscovery_equipment_manage_focus_2": "parc automobile.",
  "starter_featuresDiscovery_equipment_recurrence_focus": "Définissez des paramètres de récurrence sur vos différents outillages,",
  "starter_featuresDiscovery_equipment_recurrence": "%focusedText% pour être notifié des actions de maintenance à effectuer.",
  "starter_featuresDiscovery_equipment_title": "Assurez le suivi et l’entretien de vos équipements.",
  "starter_featuresDiscovery_equipment_upsale": "Passez à la formule Expert et suivez l’entretien de l’ensemble de vos équipements ! 🚀",
  "starter_featuresDiscovery_team_contacts_focus": "Entrez les contacts des prestataires externes",
  "starter_featuresDiscovery_team_contacts": "%focusedText% avec lesquels vous collaborez, et transmettez-leur automatiquement les demandes dont ils sont en charge !",
  "starter_featuresDiscovery_team_manage_roles_focus_1": "Indiquez les membres des équipes et leurs rôles :",
  "starter_featuresDiscovery_team_manage_roles_1": "%focusedText1% Administrateur, responsable, agent, consultant…",
  "starter_featuresDiscovery_team_manage_roles_focus_2": "Attribuez en quelques clics une demande d’intervention",
  "starter_featuresDiscovery_team_manage_roles_2": "%focusedText2% à la bonne équipe selon leur domaine d’expertise !",
  "starter_featuresDiscovery_team_title": "Paramétrez et gérez les équipes de votre service technique.",
  "starter_featuresDiscovery_team_upsale": "Passez à la formule Standard ou Expert pour fluidifier les échanges entre les collaborateurs et faciliter l’accès aux informations sur les interventions ! 🚀",
  "starter_featuresDiscovery_planning_absence_focus": "Soyez alerté automatiquement des absences des membres de votre organisation : ",
  "starter_featuresDiscovery_planning_absence": "%focusedText1% congés, maladies, jours fériés…",
  "starter_featuresDiscovery_planning_availability_focus": "Visualisez la prochaine disponibilité de l’équipe",
  "starter_featuresDiscovery_planning_availability": "%focusedText2% et planifiez les ressources nécessaires pour intervenir.",
  "starter_featuresDiscovery_planning_dragAndDrop_focus": "Grâce à un système de glisser / déposer,",
  "starter_featuresDiscovery_planning_dragAndDrop": "%focusedText% planifiez et attribuez en quelques clics les interventions aux agents.",
  "starter_featuresDiscovery_planning_organize": "Visualisez en un coup d’œil les tâches en cours ou à venir pour les différentes équipes.",
  "starter_featuresDiscovery_planning_title": "Planifiez les interventions et optimisez votre organisation.",
  "starter_featuresDiscovery_planning_upsale": "Passez à la formule Standard ou Expert pour optimiser l’organisation et la coordination de vos équipes, et faciliter la planification de leurs missions ! 🚀",
  "starter_featuresDiscovery_stats_title": "Consultez vos rapports d’activité et optimisez vos performances.",
  "starter_featuresDiscovery_stats_analyzeData_focus": "Disposez d’indicateurs clés sur l’activité du service technique :",
  "starter_featuresDiscovery_stats_analyzeData": "%focusedText% nombre d’interventions par mois, durée moyenne des travaux et analyse des coûts des interventions en fonction du temps passé, des ressources humaines et matériels.",
  "starter_featuresDiscovery_stats_exportData_focus": "Téléchargez vos données au format .csv",
  "starter_featuresDiscovery_stats_exportData": "%focusedText% pour les exploiter ensuite sur d’autres supports, ou les croiser avec d’autres chiffres.",
  "starter_featuresDiscovery_stats_upsale": "Passez à la formule Standard ou Expert pour consulter les chiffres clés de votre service technique, et ainsi optimiser vos performances et atteindre vos objectifs ! 🚀",
  "starter_featuresDiscovery_newIssue_title": "Créez et éditez vos propres demandes d’intervention.",
  "starter_featuresDiscovery_newIssue_creation_focus": "Éditez vos demandes d’intervention sur l’Espace public, les bâtiments ou les équipements en toute autonomie :",
  "starter_featuresDiscovery_newIssue_creation": "%focusedText% indiquez l’adresse, choisissez une catégorie, ajoutez une photo et le tour est joué !",
  "starter_featuresDiscovery_newIssue_mobile_focus": "créez également vos signalement à partir de l’application mobile Agent, et donnez des droits aux acteurs territoriaux de votre choix :",
  "starter_featuresDiscovery_newIssue_mobile": "Parce que la journée de vos agents ne se passe pas derrière un ordinateur, %focusedText% directeur d’école, membre d’une association…",
  "starter_featuresDiscovery_newIssue_upsale": "Passez à la formule Standard ou Expert pour créer vos propre demandes d’intervention, et bénéficiez d’une application mobile Agent ! 🚀",
  "starter_f_session_userConnected_logout": "Se déconnecter",
  "starter_f_session_userConnected_notificationSettings": "Paramètres de notification",
  "state": " Statut",
  "state_formStateChange_cancel": "Annuler",
  "state_formStateChange_label_comment": "Commentaire",
  "state_formStateChange_label_files": "Fichier",
  "state_formStateChange_validate": "Valider",
  "statistician": "Accès statistique",
  "statistics": "Statistiques",
  "statistics_3_last_month": "Les 3 derniers mois",
  "statistics_6_last_month": "Les 6 derniers mois",
  "statistics_building_categories": "Bâtiments - Catégories les plus utilisées",
  "statistics_building_categories_csv_title": "Activité bâtiments par catégorie",
  "statistics_csv_building": "Bâtiment",
  "statistics_csv_category": "Catégorie",
  "statistics_csv_creators": "Créateur",
  "statistics_csv_day": "Jour",
  "statistics_csv_equipment": "Équipement",
  "statistics_csv_nbrIssues": "Nombre de signalement(s)",
  "statistics_csv_requestors": "Demandeur",
  "statistics_current_month": "Le mois en cours",
  "statistics_current_week": "La semaine en cours",
  "statistics_current_year": "L'année en cours",
  "statistics_custom_date": "Personnalisé",
  "statistics_custom_date_stringify": "Du %start% au %end%",
  "statistics_equipment_categories": "Véhicules & Equipements - Catégories les plus utilisées",
  "statistics_equipment_categories_csv_title": "Activité équipements par catégorie",
  "statistics_filter_period_input_period_end_label": "Fin",
  "statistics_filter_period_input_period_start_label": "Début",
  "statistics_filters_agents": "Agents",
  "statistics_filters_buildings": "Bâtiments",
  "statistics_filters_categories": "Catégories",
  "statistics_filters_managers": "Gestionnaires",
  "statistics_filters_period": "Période d'analyse",
  "statistics_filters_teams": "Équipes",
  "statistics_from_to": "Du %start% au %end%",
  "statistics_issue_state_csv_title": "Nombre de signalement(s) par état",
  "statistics_issue_state_state": "État",
  "statistics_issues_device_origin": "Web vs Smartphone",
  "statistics_issues_resolved": "Résolus",
  "statistics_issues_state": "Répartition par état d'avancement",
  "statistics_issues_type": "Esp. public / Bât. / Equip.",
  "statistics_issues_user_origin": "Citoyens vs Internes",
  "statistics_last_month": "Le mois dernier",
  "statistics_last_week": "La semaine dernière",
  "statistics_last_year": "L'année dernière",
  "statistics_module_access": "Accès au module \"Statistiques\"",
  "statistics_most_actif_building": "Bâtiments les plus actifs",
  "statistics_most_actif_building_csv_title": "Activité bâtiments",
  "statistics_most_actif_creators": "Créateurs les plus actifs",
  "statistics_most_actif_creators_csv_title": "Activité par créateurs",
  "statistics_most_actif_equipment": "Equipements les plus actifs",
  "statistics_most_actif_equipment_csv_title": "Activité équipements",
  "statistics_most_actif_requestors": "Demandeurs les plus actifs",
  "statistics_most_actif_requestors_csv_title": "Activité par demandeurs",
  "statistics_myFilters": "Mes filtres de statistiques",
  "statistics_nbr_issues_in_time_csv_title": "Nombre de signalement(s) par jour",
  "statistics_new_issues": "Nouveaux",
  "statistics_new_issues_in_time": "Nouvelles interventions",
  "statistics_no_data": "Pas de données",
  "statistics_publicSpace_categories": "Espace public - Catégories les plus utilisées",
  "statistics_publicSpace_categories_csv_title": "Activité espace public par catégorie",
  "statistics_resolved_time": "Durée de résolution",
  "statistics_resolved_time_days": "Jours",
  "statistics_unresolved_since_2_month": "Non résolus > 2 mois",
  "statistics_valorization_by_category_cost": "Valorisation par catégorie en Euro",
  "statistics_valorization_by_category_csv_title": "Valorisation par catégorie",
  "statistics_valorization_by_category_hour": "Valorisation par catégorie en heure",
  "statistics_valorization_by_type": "Type",
  "statistics_valorization_by_type_cost": "Valorisation par type en Euro",
  "statistics_valorization_by_type_csv_title": "Valorisation par type",
  "statistics_valorization_by_type_hour": "Valorisation par type en heure",
  "statistics_valorization_cost": "Coût",
  "statistics_valorization_in_time_cost": "Valorisation par date en Euro",
  "statistics_valorization_in_time_csv_title": "Valorisation par jour",
  "statistics_valorization_in_time_hour": "Valorisation par date en heure",
  "statistics_valorization_quantity": "Heure",
  "subscription_asFollower": "Pour les interventions que je suis",
  "subscription_asManager": "Pour les interventions dont je suis gestionnaire",
  "subscription_asReceiver": "Pour toute intervention",
  "subscription_onCitizenCommentAsManager": "Nouveau message d'un citoyen sur un signalement",
  "subscription_onCommentAsReceiver": "Nouveau message qui m'est adressé",
  "subscription_onIssueCreatedAsManager": "Lorsqu'un nouveau signalement est introduit",
  "subscription_onIssueCreatedAsFollower": "Lorsqu'un nouveau signalement est introduit",
  "subscription_onIssueStateUpdatedAsFollower": "À chaque modification de statut",
  "subscription_onIssueStateUpdatedAsManager": "À chaque modification de statut",
  "subscription_onIssueUpdatedAsManager": "Pour toute autre modification",
  "subscription_asAgent": "Pour mes assignations",
  "subscription_onAssignedToAnIssue": "Lorsque l’on m’assigne moi ou mon équipe à la résolution d’un signalement",
  "subscription_onMonthlyStatisticReport": "Réception statistiques mensuelles",
  "subscription_onWeeklyStatisticReport": "Réception rapports hebdomadaires",
  "subscription_starter_alert_cancel": "Annuler",
  "subscription_starter_alert_infos": "Cela signifie que vous ne serez plus informé lorsqu’une nouvelle intervention sera signalée sur votre collectivité.",
  "subscription_starter_alert_submit": "Confirmer mes préférences",
  "subscription_starter_alert_subtitle": "Vous allez désactiver toutes les notifications liées à un nouveau signalement !", 
  "subscription_starter_alert_title": "Attention !",
  "subscription_starter_marketingCommunications": "Contenus qui pourrait vous intéresser / Informations sur nos offres / Opinions et enquêtes",
  "subscription_starter_marketing": "Marketing",
  "subscription_starter_newIssue": "Nouveau signalement (recommandé)",
  "subscription_starter_onCitizenComment": "Recevez une notification si un citoyen laisse un commentaire sur un signalement.",
  "subscription_starter_onIssueCreated": "Recevez une notification lorsqu’un nouveau signalement est émis.",
  "subscription_statisticReport": "Rapports statistiques",
  "super_administrator": "Super administrateur",
  "supplies": "Fournitures",
  "supplies_select_title": "Fournitures",
  "supply": "Fourniture",
  "stdXp_session_userConnected_logout": "Se déconnecter",
  "stdXp_session_userConnected_notificationSettings": "Paramètres de notification",
  "stdXp_session_userConnected_copyCalendarLink": "Copier le lien du calendrier",
  "tablet_access": "Accès tablette",
  "tablet_access_nudge": "Permet de paramétrer l'accès à l'application \"Betterstreet Tablette\"",
  "team": "Équipe",
  "team_filter_member": "Agent",
  "team_filter_with": "Avec",
  "team_filter_withMember": "Avec agent",
  "team_filter_without": "Sans",
  "team_filter_withoutMember": "Sans agent",
  "team_form_photography": "Photographie",
  "team_logs_disabled": "Passage à l'état désactivé",
  "team_logs_enabled": "Passage à l'état activé",
  "team_logs_members_add": "Ajout de membre(s)",
  "team_logs_members_drop": "Suppression de membre(s)",
  "team_logs_name": "Changement de nom",
  "team_logs_type_external": "Passage au type externe",
  "team_logs_type_internal": "Passage au type interne",
  "team_logs_valorization": "Modification de la valorisation",
  "team_members_selection": "Ajoutez des membres à l'équipe",
  "teams": "Équipes",
  "teamsAndAgents_select_search": "Chercher",
  "teamsAndAgents_select_stopSelection": "Annuler",
  "teamsAndAgents_select_title": "Équipes & Agents",
  "teamsAndAgents_select_validSelection": "Valider",
  "teams_planning": "Planning des équipes",
  "temporary_password": "Mot de passe temporaire (à saisir 2 fois)",
  "tenant": "Structure",
  "tenant_configuration_impose_at_least_one_tag": "La configuration de la structure impose au moins une étiquette",
  "tenant_filter_citizen": "Signalement citoyen",
  "tenant_filter_citizen_allowed": "Accepté",
  "tenant_filter_citizen_notAllowed": "Refusé",
  "tenant_filter_citizen_str_allowed": "Signalement citoyen accepté",
  "tenant_filter_citizen_str_notAllowed": "Signalement citoyen refusé",
  "tenant_filter_commercialOffer": "Offre commerciale",
  "tenant_filter_commercialOffer_expert": "Expert",
  "tenant_filter_commercialOffer_standard": "Standard",
  "tenant_filter_commercialOffer_starter": "Starter",
  "tenant_filter_country": "Pays",
  "tenant_filter_type": "Type",
  "tenant_form_citizen_portal": "Portail citoyen",
  "tenant_form_clientIdJvs": "Numéro de client chez JVS",
  "tenant_form_connector_gp": "Connecteur vers gestion du personnel",
  "tenant_form_connector_gp_jvs_client": "Identifiant du client",
  "tenant_form_connector_gp_jvs_component": "Component",
  "tenant_form_connector_gp_jvs_entity": "Entité",
  "tenant_form_connector_gp_jvs_username": "Utilisateur",
  "tenant_form_connector_gp_type": "Type de connecteur",
  "tenant_form_connector_gp_type_any": "Aucun",
  "tenant_form_empty_generic_day_error": "Vous n'avez pas donné de nom à l'un des jours chomés supplémentaires",
  "tenant_form_expertOffer": "Expert",
  "tenant_form_holidayDay_generic": "Jours chômés supplémentaires",
  "tenant_form_holidayDay_standart": "Jours fériés standards",
  "tenant_form_holidayDay_standarts_allSaintsDay": "Toussaint (%date%)",
  "tenant_form_holidayDay_standarts_armistice1918": "Armistice de 1918 (%date%)",
  "tenant_form_holidayDay_standarts_ascent": "Jeudi de l'Ascension (%date%)",
  "tenant_form_holidayDay_standarts_assumption": "Assomption (%date%)",
  "tenant_form_holidayDay_standarts_belgiumNationalDay": "Fête nationale Belge (%date%)",
  "tenant_form_holidayDay_standarts_christmasDay": "Noël (%date%)",
  "tenant_form_holidayDay_standarts_easterDay": "Pâques (%date%)",
  "tenant_form_holidayDay_standarts_easterMonday": "Lundi de Pâques (%date%)",
  "tenant_form_holidayDay_standarts_europeDay": "Jour de l'Europe (%date%)",
  "tenant_form_holidayDay_standarts_frenchNationalDay": "Fête nationale Française (%date%)",
  "tenant_form_holidayDay_standarts_frenchVictory1945": "Victoire de 1945 (%date%)",
  "tenant_form_holidayDay_standarts_goodFriday": "Vendredi Saint (%date%)",
  "tenant_form_holidayDay_standarts_laborDay": "Fête du Travail (%date%)",
  "tenant_form_holidayDay_standarts_luxemburgNationalDay": "Fête nationale Luxembourgeoise (%date%)",
  "tenant_form_holidayDay_standarts_newYearDay": "Jour de l'an (%date%)",
  "tenant_form_holidayDay_standarts_pentecost": "Pentecôte (%date%)",
  "tenant_form_holidayDay_standarts_pentecostMonday": "Lundi de la Pentecôte (%date%)",
  "tenant_form_holidayDay_standarts_saintStephenDay": "Saint Étienne (%date%)",
  "tenant_form_informations_phone": "Téléphone",
  "tenant_form_informations_postalCode": "Code postal",
  "tenant_form_informations_siren": "Siren",
  "tenant_form_informations_unknown": "Non renseigné",
  "tenant_form_internalConfiguration": "Configuration interne",
  "tenants_form_latitude" : "Latitude",
  "tenants_form_longitude" : "Longitude",
  "tenant_form_main_user": "Utilisateur principal",
  "tenant_form_mandatoryTagsOnCategories": "Étiquettes obligatoires sur les catégories",
  "tenant_form_mandatoryTagsOnCategories_no": "Non",
  "tenant_form_mandatoryTagsOnCategories_yes": "Oui",
  "tenant_form_noOffer": "Aucune",
  "tenant_form_standardOffer": "Standard",
  "tenant_form_starterOffer": "Starter",
  "tenant_form_subscription_label": "Offre commerciale",
  "tenant_form_usersProLimitedToSettings": "Limiter les utilisateurs pros à la partie settings",
  "tenant_form_workingDay": "Jours travaillés",
  "tenant_form_workingDay_weekWorkingDays": "Jours ouvrables",
  "tenant_form_workingDay_weekWorkingDays_friday": "Vendredi",
  "tenant_form_workingDay_weekWorkingDays_monday": "Lundi",
  "tenant_form_workingDay_weekWorkingDays_saturday": "Samedi",
  "tenant_form_workingDay_weekWorkingDays_sunday": "Dimanche",
  "tenant_form_workingDay_weekWorkingDays_thursday": "Jeudi",
  "tenant_form_workingDay_weekWorkingDays_tuesday": "Mardi",
  "tenant_form_workingDay_weekWorkingDays_wednesday": "Mercredi",
  "tenant_invite_not_client_success": "Invitation envoyée avec succès",
  "tenant_invite_self_invitation_delay": "Invitation déjà envoyée, veuillez valider la précédente avant de réessayer",
  "tenant_item_action_invite": "Inviter",
  "tenant_item_citizen_allowed": "Citizen",
  "tenant_item_client": "Client",
  "tenant_item_commercialOffer": "Offre commerciale",
  "tenant_item_commercialOffer_none" : "Aucune",
  "tenant_item_commercialOffer_starter": "Starter",
  "tenant_item_commercialOffer_standard": "Standard",
  "tenant_item_commercialOffer_expert": "Expert",
  "tenant_item_invite_modal_form_defaultEmail": "Email par défault de la commune",
  "tenant_item_invite_modal_form_submit": "Envoyer l'invitation",
  "tenant_item_invite_modal_form_type": "Type de compte",
  "tenant_item_invite_modal_form_type_attractiveCity": "Attractive City",
  "tenant_item_invite_modal_form_type_betterstreet": "BetterStreet",
  "tenant_item_invite_modal_form_type_panneauPocket": "Panneau Pocket",
  "tenant_item_invite_modal_title": "Confirmation des informations",
  "tenant_item_type": "Type",
  "tenant_logs_admin_issues_default_public": "Signalement admin visibles sur le portail citoyen",
  "tenant_logs_admin_issues_default_public_yes": "Oui",
  "tenant_logs_admin_issues_default_public_no": "Non",
  "tenant_logs_citizen_allowed": "Ouverture du portail citoyen",
  "tenant_logs_citizen_allowed_yes": "Oui",
  "tenant_logs_citizen_allowed_no": "Non",
  "tenant_logs_clientIdJvs": "Modification du numéro de client JVS",
  "tenant_logs_clientIdJvs_undefined": "Non défini",
  "tenant_logs_commercialOffer": "Changement d'offre commerciale",
  "tenant_logs_commercialOffer_not_client": "Non cliente",
  "tenant_logs_invite_not_client": "Invitation envoyée",
  "tenant_logs_name": "Changement de nom",
  "tenant_logs_creation": "Création de la structure",
  "tenant_saved": "Sauvegarde de la structure effectuée avec succès",
  "tenants": "Structures",
  "tenants_not_client_contact": "Si vous pensez qu'il s'agit d'une erreur, merci de contacter %link%.",
  "tenants_not_client_contact_link": "notre support",
  "tenants_not_client_information": "Votre structure \"%tenant%\" n'est pas configurée comme cliente.",
  "to_do": "À faire",
  "tools_link_label": "Outils",
  "tooltip_generalData_manager_label": "Gestionnaire",
  "town": "Commune",
  "transferred": "Transféré",
  "type": "Type",
  "unallocated_workorders_visible": "Bons de travail non attribués individuellement visibles par tous les agents de l'équipe",
  "undefined": "Non défini",
  "unknown": "Inconnu",
  "urgent": "Urgent",
  "user": "Utilisateur",
  "user_filter_skill": "Compétence",
  "user_form_colloaborator_geographicalArea": "Zone géographique",
  "user_form_colloaborator_geographicalArea_be": "Belgique",
  "user_form_colloaborator_geographicalArea_fr": "France",
  "user_form_colloaborator_geographicalArea_lu": "Luxembourg",
  "user_form_colloaborator_role": "Rôles",
  "user_form_colloaborator_role_appAdmin": "Administrateur de l'application",
  "user_form_colloaborator_role_issueProvider": "Fournisseur de signalement",
  "user_form_colloaborator_role_superAdmin": "Super administrateur",
  "user_form_discriminator": "Type d'utilisateur",
  "user_form_discriminator_collaborator": "Collaborateur",
  "user_form_discriminator_pro": "Utilisateur",
  "user_form_discriminator_tablet": "Tablette",
  "user_form_followedCategories": "Suiveur des catégories suivantes :",
  "user_form_followedCategories_tenant_name": "Nom de la structure",
  "user_form_label_skill": "Compétence",
  "user_form_managedCategories": "En charge des catégories suivantes :",
  "user_form_managedCategories_tenant_name": "Nom de la structure",
  "user_form_tablet_tenant": "Tenant",
  "user_form_tenantConfiguration_building_by_building": "Limitation par bâtiment",
  "user_form_tenantConfiguration_building_by_tag": "Limitation par étiquette de bâtiment",
  "user_form_tenantConfiguration_building_by_tag_add_btn": "Ajouter",
  "user_form_tenantConfiguration_canValorizeForOthers": "Peut valoriser pour les autres",
  "user_form_tenantConfiguration_connector": "Configuration du connecteur GP",
  "user_form_tenantConfiguration_connector_horizongp_agent": "Agent",
  "user_form_tenantConfiguration_connector_horizongp_entity": "Entité",
  "user_form_tenantConfiguration_connector_horizongp_service": "Service",
  "user_form_tenantConfiguration_connectorlink": "Connecteur",
  "user_form_tenantConfiguration_connectorlink_horizonJVS": "Relié à \"%agent%\"",
  "user_form_tenantConfiguration_readable_building_by_building": "Limitation par bâtiment : ",
  "user_form_tenantConfiguration_readable_building_by_tag": "Limitation par étiquette de bâtiment : ",
  "user_form_username_already_exist": "Ce nom d'utilisateur est déjà utilisé",
  "user_item_copy": "Copier",
  "user_item_building_limitation_all": "Tous",
  "user_item_building_limitation_limited": "Limité",
  "user_item_description_buildings": "Bâtiments",
  "user_item_description_roles": "Rôles",
  "user_item_lastInvitation": "Dernière invitation : %last_invitation%",
  "user_list_createTechnicalUser": "Créer utilisateur technique",
  "user_profil": "Profil utilisateur",
  "user_technicalUser_application": "Application",
  "user_technicalUser_creation_title": "Création d'un utilisateur technique",
  "user_technicalUser_default_public_issue": "Signalement créés publics par défaut",
  "user_technicalUser_footer_cancel": "Annuler",
  "user_technicalUser_footer_close": "Fermer",
  "user_technicalUser_footer_regeneratePassword": "Regénérer le mot de passe",
  "user_technicalUser_footer_save": "Enregistrer",
  "user_technicalUser_fullname": "Nom",
  "user_technicalUser_input_application_ac": "Attractive city",
  "user_technicalUser_input_application_gs": "Gestion de salle",
  "user_technicalUser_input_application_pp": "Panneau pocket",
  "user_technicalUser_input_defaultPublicIssue_true": "Oui",
  "user_technicalUser_input_defaultPublicIssue_false": "Non",
  "user_technicalUser_password": "Mot de passe",
  "user_technicalUser_tenant": "Structure",
  "user_technicalUser_username": "Identifiant",
  "user_technicalUser_view_title": "Consultation d'un utilisateur technique",
  "username": "Nom d'utilisateur",
  "users": "Utilisateurs",
  "users_country_be": "Belgique",
  "users_country_fr": "France",
  "users_country_lu": "Luxembourg",
  "users_filter_collaborator_role": "Rôles de collaborateur",
  "users_filter_collaborator_role_appAdmin": "Administrateur de l'application",
  "users_filter_collaborator_role_issueProvider": "Fournisseur de signalements",
  "users_filter_collaborator_role_superAdmin": "Super administrateur",
  "users_form_user_connectorGP_title": "Association à un utilisateur de la gestion du personnel",
  "users_filter_country": "Limitation par pays",
  "users_filter_discriminator_collaborator": "Collaborateur",
  "users_filter_discriminator_pro": "Utilisateur",
  "users_filter_discriminator_tablet": "Tablette",
  "users_filter_discriminator_technical": "Technique",
  "users_form_user_avatar_section_title": "Photographie",
  "users_form_user_generalInformations_section_title": "Informations générales",
  "users_form_user_generalInformations_firstname_label": "Prénom",
  "users_form_user_generalInformations_lastname_label": "Nom",
  "users_form_user_generalInformations_disabled_label": "Actif",
  "users_form_user_generalInformations_disabled_false": "Oui",
  "users_form_user_generalInformations_disabled_true": "Non",
  "users_form_user_generalInformations_discriminator_label": "Type d'utilisateur",
  "users_form_user_generalInformations_discriminator_pro": "Utilisateur",
  "users_form_user_generalInformations_discriminator_collaborator": "Collaborateur",
  "users_form_user_workOrder_section_title": "Bon de travail",
  "users_form_user_workOrder_valorization_label": "Coût horaire",
  "users_form_user_workOrder_nbrDisplayedDays_condition_label": "Limiter le nombre de jours visibles sur la feuille de route",
  "users_form_user_workOrder_nbrDisplayedDays_condition_yes": "Oui",
  "users_form_user_workOrder_nbrDisplayedDays_condition_no": "Non",
  "users_form_user_workOrder_nbrDisplayedDays_value_label": "Nombre de jours visibles sur la feuille de route",
  "users_form_user_authentificationProvider_section_title": "Données d'authentification",
  "users_form_user_authentificationProviders_type_label": "Type d'identification",
  "users_form_user_authentificationProviders_type_email": "Email",
  "users_form_user_authentificationProviders_type_username": "Nom d'utilisateur",
  "users_form_user_authentificationProviders_type_any": "Aucun",
  "users_form_user_authentificationProviders_email_label": "Adresse e-mail",
  "users_form_user_authentificationProviders_username_label": "Nom d'utilisateur",
  "users_form_user_authentificationProviders_password_info": "Le mot de passe doit avoir une taille d'au moins 8 caractères et comporter au moins une majuscule (A-Z), une minuscule (a-z) et un caractère numérique (0-9).",
  "users_form_user_authentificationProviders_password_label": "Mot de passe temporaire (à saisir 2 fois)",
  "users_form_user_roles_section_title": "Rôles et droits d'accès",
  "users_form_user_roles_section_roles_label": "Rôles",
  "users_form_user_roles_section_roles_superAdmin": "Super administrateur",
  "users_form_user_roles_section_roles_appAdmin": "Administrateur de l'application",
  "users_form_user_roles_section_roles_issueProvider": "Fournisseur de signalements",
  "users_form_user_roles_section_geographicalAreas_label": "Zone géographique",
  "users_form_user_roles_section_geographicalAreas_france": "France",
  "users_form_user_roles_section_geographicalAreas_belgium": "Belgique",
  "users_form_user_roles_section_geographicalAreas_luxembourg": "Luxembourg",
  "users_form_user_roles_section_cityCodeFilter_label": "Restreindre par code postal",
  "users_form_user_roles_section_tenants_label": "Structures",
  "users_form_user_tenantRolesForm_disabled_label": "Actif",
  "users_form_user_tenantRolesForm_disabled_true": "Non",
  "users_form_user_tenantRolesForm_disabled_false": "Oui",
  "users_form_user_tenantRolesForm_othersRoles_label": "Rôles",
  "users_form_user_tenantRolesForm_othersRoles_admin": "Administrateur",
  "users_form_user_tenantRolesForm_othersRoles_admin_helper": "Le rôle Administrateur permet d’accéder à toutes les fonctions ainsi qu'à la configuration de la structure. Compatible avec le rôle agent pour permettre l’assignation.",
  "users_form_user_tenantRolesForm_othersRoles_manager": "Responsable",
  "users_form_user_tenantRolesForm_othersRoles_manager_helper": "Le rôle Responsable permet d’accéder à toutes les fonctions hormis la configuration de la structure. Compatible avec le rôle agent pour permettre l’assignation.",
  "users_form_user_tenantRolesForm_othersRoles_categoryManager": "Responsable de catégorie",
  "users_form_user_tenantRolesForm_othersRoles_categoryManager_helper": "Le rôle Responsable de catégorie permet de mettre à jour et planifier des signalements sur la ou les catégories dont il est le gestionnaire, ainsi que créer des signalements sur l’ensemble des catégories. Compatible avec le rôle agent pour permettre l’assignation.",
  "users_form_user_tenantRolesForm_othersRoles_agent": "Agent",
  "users_form_user_tenantRolesForm_othersRoles_agent_helper_forbidden": "Ce rôle n'est pas autorisé car l'utilisateur est déjà agent sur la structure : %forbiddenBy%",
  "users_form_user_tenantRolesForm_othersRoles_agent_helper": "Le rôle Agent permet de créer tout type de signalement et de mettre à jour tout signalement qui lui sont assignés à lui ou à son équipe.",
  "users_form_user_tenantRolesForm_othersRoles_statistician": "Accès statistiques",
  "users_form_user_tenantRolesForm_othersRoles_statistician_helper": "Permet l’accès à la page des statistiques.",
  "users_form_user_tenantRolesForm_issuersRoles_label": "Créateur",
  "users_form_user_tenantRolesForm_issuersRoles_publicSpaceIssuer": "Espace public",
  "users_form_user_tenantRolesForm_issuersRoles_publicSpaceIssuer_helper": "Le rôle Créateur espace public permet la création de signalement de type Espace Public",
  "users_form_user_tenantRolesForm_issuersRoles_buildingIssuer": "Bâtiment",
  "users_form_user_tenantRolesForm_issuersRoles_buildingIssuer_helper": "Le rôle Créateur bâtiment permet la création de signalement de type Bâtiment. Il peut être limité à certains bâtiments.",
  "users_form_user_tenantRolesForm_issuersRoles_equipmentIssuer": "Équipement",
  "users_form_user_tenantRolesForm_issuersRoles_equipmentIssuer_helper": "Le rôle Créateur équipement permet la création de signalement de type Équipement.",
  "users_form_user_tenantRolesForm_viewersRoles_label": "Consultant",
  "users_form_user_tenantRolesForm_viewersRoles_publicSpaceViewer": "Espace public",
  "users_form_user_tenantRolesForm_viewersRoles_publicSpaceViewer_helper": "Le rôle Consultant espace public permet de consulter tous les signalements de type Espace Public.",
  "users_form_user_tenantRolesForm_viewersRoles_buildingViewer": "Bâtiment",
  "users_form_user_tenantRolesForm_viewersRoles_buildingViewer_helper": "Le rôle Consultant bâtiment permet de consulter tous les signalements de type Bâtiment. Il peut être limité à certains bâtiments.",
  "users_form_user_tenantRolesForm_viewersRoles_equipmentViewer": "Équipement",
  "users_form_user_tenantRolesForm_viewersRoles_equipmentViewer_helper": "Le rôle Consultant équipement permet de consulter tous les signalements de type Équipement.",
  "users_form_user_selectTenant_validate_button": "Valider",
  "users_form_user_selectTenant_cancel_button": "Annuler",
  "users_form_user_validateTenantDrop_label": "Êtes-vous sur de vouloir supprimer la configuration de la structure %tenant% pour l'utilisateur %user%?",
  "users_form_user_validateTenantDrop_validate": "Supprimer",
  "users_form_user_validateTenantDrop_cancel": "Annuler",
  "users_form_user_tenantRolesForm_teamApplication_label": "Autoriser la connexion sur l'application en mode équipe",
  "users_form_user_tenantRolesForm_teamApplication_true": "Oui",
  "users_form_user_tenantRolesForm_teamApplication_false": "Non",
  "users_form_user_tenantRolesForm_buildingLimitation_label": "Limitation Bâtiment",
  "users_form_user_tenantRolesForm_buildingLimitation_byId_label": "Par bâtiment :",
  "users_form_user_tenantRolesForm_buildingLimitation_byTag_label": "Par étiquette de bâtiment :",
  "users_form_user_noEmailTenantRole_tenant_label": "Structure",
  "users_form_user_noEmailTenantRole_teamApplication_label": "Autoriser la connexion sur l'application en mode équipe",
  "users_form_user_noEmailTenantRole_teamApplication_true": "Oui",
  "users_form_user_noEmailTenantRole_teamApplication_false": "Non",
  "users_form_user_noEmailTenantRole_roles_label": "Rôle",
  "users_form_user_noEmailTenantRole_roles_agent": "Agent",
  "users_form_user_noEmailTenantRole_disabled_label": "Actif",
  "users_form_user_noEmailTenantRole_disabled_false": "Oui",
  "users_form_user_noEmailTenantRole_disabled_true": "Non",
  "users_form_user_error_no_role_on_tenants": "Vous n'avez pas sélectionné de rôle pour les structures %tenantNames%",
  "user_form_title": "Utilisateur -- %user%",
  "user_form_new": "Nouveau",
  "user_form_submit": "Sauver",
  "user_without_tenant_info": "Vous n’avez accès à aucune structure.",
  "user_without_tenant_contact_support": "Si vous pensez qu'il s'agit d'une erreur, merci de contacter notre support en cliquant %supportLink%",
  "user_without_tenant_contact_support_prompt": "juste ici",
  "user_without_tenant_try_another_user": "Réessayer avec un autre utilisateur",
  "validate_user_unknow_role": "Rôle utilisateur inconnu",
  "valorization_form_addEquipment": "Ajouter",
  "valorization_form_addService": "Ajouter",
  "valorization_form_addSuply": "Ajouter",
  "valorization_form_addTeamAndAgent": "Ajouter",
  "valorization_form_cancel": "Annuler",
  "valorization_form_delete": "Supprimer",
  "valorization_form_externalService_cancel": "Annuler",
  "valorization_form_externalService_delete": "Supprimer",
  "valorization_form_externalService_label_cost": "Coût",
  "valorization_form_externalService_label_description": "Description",
  "valorization_form_externalService_label_externalReference": "Référence externe",
  "valorization_form_externalService_label_name": "Nom",
  "valorization_form_externalService_title": "Prestations externes",
  "valorization_form_externalService_valid": "Valider",
  "valorization_form_header_assignmentInfo": "Seuls les équipes, les agents, les équipements et les fournitures possédant un coût horaire ou unitaire sont sélectionnables et visibles. Vous devez vous rapprocher d'un administrateur afin qu'il renseigne cette donnée si vous ne trouvez pas un élément.",
  "valorization_form_header_cost": "Coût total",
  "valorization_form_header_quantity": "Quantité",
  "valorization_form_header_statementFor": "Valorisation pour",
  "valorization_form_header_statementOf": "Date de la valorisation",
  "valorization_form_header_unitCost": "Coût par unité",
  "valorization_form_no_statement_error": "Déclarations avec quantité vide, supprimez ces déclarations ou ajoutez leur une quantité",
  "valorization_form_target_assignment": "Assignation \"%assignment%\"",
  "valorization_form_target_externalService": "Prestation externe",
  "valorization_form_target_service": "Prestation externe",
  "valorization_form_valid": "Valider",
  "valorization_item_equipment": "Équipements",
  "valorization_item_header_quantity": "Quantité",
  "valorization_item_header_statementOf": "Date de valorisation",
  "valorization_item_header_total_cost": "Coût total",
  "valorization_item_header_unit_cost": "Coût par unité",
  "valorization_item_header_update": "Modifier",
  "valorization_item_service": "Prestations externes",
  "valorization_item_supply": "Fournitures",
  "valorization_item_teamNAgent": "Équipes & Agents",
  "valorization_modal_form_title": "Valorisation pour signalement %issue%",
  "valorization_settings": "Paramètres de valorisation",
  "valorization_summary_agentTeam": "Équipes & Agents",
  "valorization_summary_equipment": "Équipements",
  "valorization_summary_service": "Prestations externes",
  "valorization_summary_supply": "Fournitures",
  "valorization_summary_total": "Total",
  "valorizations_create": "Ajouter une valorisation",
  "valorizations_equipments": "Équipements",
  "valorizations_externalPrestations": "Prestations externes",
  "valorizations_humanResources": "Equipes & Agents",
  "valorizations_report_date": "Date de valorization",
  "valorizations_report_quantity": "Quantité",
  "valorizations_report_total": "Coût total",
  "valorizations_report_unitPrice": "Coûts TTC par unité en euros",
  "valorizations_report_update": "Modifier",
  "valorizations_setByType_hideDetail": "Cacher le détail",
  "valorizations_setByType_showDetail": "Voir le détail",
  "valorizations_setForAssignment_title": "Valorisations de l'assignation \"%assignment%\"",
  "valorizations_setForIssue_title": "Prestations externes",
  "valorizations_supplies": "Fournitures",
  "valorizations_total": "Total",
  "views_header_assignments": "Mes assignations",
  "views_header_createIssue": "Nouveau Signalement",
  "views_header_home": "Accueil",
  "views_header_issues": "Signalements",
  "views_header_planning": "Planning",
  "views_header_recurrences": "Récurrences",
  "views_header_settings": "Paramètres",
  "views_header_statistics": "Statistiques",
  "visibility": "Visibilité",
  "visible_schedule_number_days": "Nombre de jours visibles sur la feuille de route",
  "weekly_planning": "Planning hebdomadaire",
  "weekly_planning_week_period": "Semaine %week% du %start% au %end%",
  "with_support_of": "Avec le soutien de",
  "without_tablet_access": "Sans accès tablette",
  "workorder": "Bon de travail",
  "workorders_settings": "Paramètres des bons de travail",
  "yes": "Oui",
  "your_firstname": "Votre prénom",
  "your_lastname": "Votre nom"  
};

export default i18n;
