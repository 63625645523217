import React         from "react";
import Acl           from "@cBehaviour/acl";
import Form          from '@cBehaviour/form';
import T             from '@cBehaviour/i18n';
import Button        from '@cComponents/button';
import Display       from '@cComponents/displayIf';
import Field         from "@cComponents/old/field";
import FormError     from "@cComponents/formError";
import FieldGroup    from "@components/fieldGroup";
import Input         from "@cComponents/old/input";
import FInput        from '@components/input';
import Application   from "@uBehaviour/application";
import NInput        from "@cComponents/input";
import Filter        from "@cComponents/filter";
import User          from "@entities/user";
import _             from "lodash";

import { CountryList as countries } from "@uTypes/business/Country";
import HolidayDays from "./holidayDays";
import WorkingDays from "./workingDays";
import "./form.css";

const getOffer = (offer) => {
  switch(offer){
    case "starter": return "tenant_form_starterOffer";
    case "standard": return "tenant_form_standardOffer";
    case "expert": return "tenant_form_expertOffer";
    default: return "tenant_form_noOffer";
  }
}

class TenantForm extends React.Component {
  constructor(props) {
    super(props);
    this._isNew = false;
    this._preload = this._preload.bind(this);
    this._postalCode = React.createRef();
  }

  _preload(value) {
    if (value.settings && value.settings.defaultMap) {
      value.location = value.settings.defaultMap;
      value.defaultMapLongitude = value.settings.defaultMap.position.coordinates[0];
      value.defaultMapLatitude = value.settings.defaultMap.position.coordinates[1];
    }
    value.pictures = {};
    value.pictures.header = value.informations.pictures.header ? [value.informations.pictures.header] : [];
    value.pictures.slider = value.informations.pictures.slider ? [value.informations.pictures.slider] : [];
    value.pictures.logo = value.informations.pictures.logo ? [value.informations.pictures.logo] : [];
    this._isNew = !value._id;
    if(this._isNew){
      const { geolocation } = this.props;
      value.location = {
        position: geolocation.current,
        zoomLevel: geolocation.defaultZoom
      };
    }
    return value;
  }

  
  _presubmit(value) {
    value.settings.defaultMap = value.location;
    value.informations.pictures = {};
    value.informations.pictures.header = value.pictures.header.length ? value.pictures.header[0] : null;
    value.informations.pictures.slider = value.pictures.slider.length ? value.pictures.slider[0] : null;
    value.informations.pictures.logo = value.pictures.logo.length ? value.pictures.logo[0] : null;
    delete value.pictures;
    delete value.location;
    delete value.discriminator;
    return value;
  }

  _validate = (value, errors) => {
    this._validateGenericDay(value, errors);
    this._validateName(value, errors);
  }

  _onFormChange = (value, origin) => {
    if (origin === "location") {
      value.defaultMapLongitude = value.location.position.coordinates[0];
      value.defaultMapLatitude = value.location.position.coordinates[1];
    }else if (origin === "defaultMapLongitude") {
      value.location.position.coordinates[0] = value.defaultMapLongitude;
    }else if (origin === "defaultMapLatitude") {
      value.location.position.coordinates[1] = value.defaultMapLatitude;
    } 
    return value;
  }
  _validateName(value, errors){
    if(!value.name?.fr || !value.name?.en || !value.name?.nl || !value.name?.de){
      errors.properties.push({
        path:"name",
        error: "required_input"
      });
    }
  }

  _validateGenericDay(value, errors) {
    if (value.settings.holidayDays.generics.some(g => g.name === "")) {
      errors.global.push({error: "tenant_form_empty_generic_day_error"})
    }
  }

  addPostalCode = () => {
    this._postalCode.current.add();
  }

  render() {
    const { userHelper, i18n } = this.props;
    return (
      <Form.Model 
        model={"Tenant"}
        partial={true}
        observer={this.props.observer} 
        preload={this._preload} 
        presubmit={this._presubmit} 
        id={this.props.id} 
        ref={this.props.forwardedRef} 
        validate={this._validate}
        onChange={this._onFormChange}
      >
        {(tenant, errors) => {
          const isClient = !!tenant.settings.commercialOffer;
          const readOnly = !this.props.acl.isAllow("tenants", "manage", this.props.session.user, tenant._id);
          return (
            <FormError errors={errors}>
              <div className="bs-form-tenant-inputs">
                <Input.Hidden name="discriminator" />
                  <FieldGroup title="name" type="full">
                    <Field>
                      <Field.Input>
                        <ReadWrite name="name" info={ i18n.translate(tenant.name) } action="manage">
                          <Input.NewToOld name="name">
                            <NInput.I18N />
                          </Input.NewToOld>
                        </ReadWrite>
                      </Field.Input>
                    </Field>
                  </FieldGroup>
                <Input.Object name="pictures">
                  <FieldGroup title="pictures" type="full">
                    <div className="bs-form-tenant-pictures">
                      <Field>
                        <Field.Label><T>pictures_header</T></Field.Label>
                        <Field.Input>
                          <Input.File name="header" limit={1} width={200} height={200} fit="contain" />
                        </Field.Input>
                      </Field>
                      <Field>
                        <Field.Label><T>pictures_slider</T></Field.Label>
                        <Field.Input>
                          <Input.File name="slider" limit={1} width={200} height={200} fit="contain" />
                        </Field.Input>
                      </Field>
                      <Field>
                        <Field.Label><T>pictures_logo</T></Field.Label>
                        <Field.Input>
                          <Input.File name="logo" limit={1} width={200} height={200} fit="contain" />
                        </Field.Input>
                      </Field>
                    </div>
                  </FieldGroup>
                </Input.Object>
                <FieldGroup title="geolocation" required={true} subTitle="geolocation_subTitle" type="full">
                  <Acl.If resource="tenants" action="manageLimited">
                    <Acl.Then>
                    <div>
                      <Field>
                        <Field.Label><T>tenants_form_latitude</T></Field.Label>
                        <Field.Input>
                          <Input.NewToOld name="defaultMapLongitude">
                            <NInput.Numeric step="0.000001" numericType="decimal" />
                          </Input.NewToOld>
                        </Field.Input>
                      </Field>
                      <Field>
                        <Field.Label><T>tenants_form_longitude</T></Field.Label>
                        <Field.Input>
                        <Input.NewToOld name="defaultMapLatitude">
                            <NInput.Numeric step="0.000001" numericType="decimal" />
                          </Input.NewToOld>
                        </Field.Input>
                      </Field>
                      </div>
                    </Acl.Then>
                  </Acl.If> 
                  <Field>
                    <Field.Input>
                      <div style={{ height: "300px" }}>
                        <Input.Map name="location" noError={true} type="mapposition" zoomOnScroll={true} />
                      </div>
                    </Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>country</T></Field.Label>
                    <Field.Input>
                      <ReadWrite name="country" info={tenant.country} action="manage">
                        <Input.Radio.Group name="country">
                        {
                          ((userHelper.geographicalAreas || countries).map(c => (
                            <Input.Radio.Value value={ c }>
                              {(selected) => (<Button.Radio pushed={selected}><T>{ "tenant_country_" + c }</T></Button.Radio>)}
                            </Input.Radio.Value>
                          )))
                        }
                        </Input.Radio.Group>
                      </ReadWrite>
                    </Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>google_country</T></Field.Label>
                    <Field.Input>
                      <ReadWriteTextInput name="googleCountry" info={tenant.googleCountry} required />
                    </Field.Input>
                  </Field>
                </FieldGroup>
                <Input.Object name="settings">
                  <FieldGroup title="workorders_settings" type="half">
                    <Field>
                      <Field.Label><T>automatic_update_clotured_workorder_agent</T></Field.Label>
                      <Field.Input><FInput.YesNo name="issueResolvedWhenTaskCompleted" /></Field.Input>
                    </Field>
                    <Display.If condition={tenant.discriminator === "town"}>
                      <FieldGroup title="tenant_form_citizen_portal" type="full">
                        <Input.Object name="citizen">
                          <Field>
                            <Field.Label>
                              <T>open_to_citizen</T>
                            </Field.Label>
                            <Field.Input><FInput.YesNo name="allowed" /></Field.Input>
                          </Field>
                          <Display.If condition={isClient}>
                            <Display.Then>
                              <Field>
                                <Field.Label>
                                  <T>admin_issues_public_by_default</T>
                                  <div className="bs-form-tenant-adminIssuesPublicByDefault-subLabel"><T>admin_issues_public_by_default_subLabel</T></div>
                                </Field.Label>
                                <Field.Input><FInput.YesNo name="adminIssuesPublicByDefault" disabled={!tenant.settings.citizen || !tenant.settings.citizen.allowed} /></Field.Input>
                              </Field>
                            </Display.Then>
                            <Display.Else>
                              <Input.Hidden noError={true} name="adminIssuesPublicByDefault" />
                            </Display.Else>
                          </Display.If>
                          <Display.If condition={!isClient && tenant.settings.citizen?.allowed}>
                            <Display.Then>
                              <Field>
                                <Field.Label>
                                  <T>default_email</T>
                                </Field.Label>
                                <Field.Input><Input.Email name="defaultEmail" /></Field.Input>
                              </Field>
                            </Display.Then>
                            <Display.Else>
                              <Input.Hidden noError={true} name="defaultEmail" />
                            </Display.Else>
                          </Display.If>
                        </Input.Object>
                      </FieldGroup>
                    </Display.If>
                  </FieldGroup>
                  <Acl.If resource="tenants" action="manageLimited">
                    <Acl.Then>
                      <FieldGroup title="generals" type="half">                  
                        <Field>
                          <Field.Label><T>tenant_form_mandatoryTagsOnCategories</T></Field.Label>
                          <Field.Input>
                            <Input.Radio.Group name="mandatoryTagsOnCategories">
                              <Input.Radio.Value value={ true }>{(selected) => (<Button.Radio pushed={selected}><T>tenant_form_mandatoryTagsOnCategories_yes</T></Button.Radio>)}</Input.Radio.Value>
                              <Input.Radio.Value value={ false }>{(selected) => (<Button.Radio pushed={selected}><T>tenant_form_mandatoryTagsOnCategories_no</T></Button.Radio>)}</Input.Radio.Value>
                            </Input.Radio.Group>
                          </Field.Input>
                        </Field>
                        <Field>
                          <Field.Label><T>tenant_form_subscription_label</T></Field.Label>
                          <Field.Input>
                            <ReadWrite name="commercialOffer" info={(<T>{ getOffer(tenant.settings.commercialOffer)}</T>) } action="manage">
                              <Input.Radio.Group name="commercialOffer" required>
                                <Input.Radio.Value value={ null }>{(selected) => (<Button.Radio pushed={selected}><T>tenant_form_noOffer</T></Button.Radio>)}</Input.Radio.Value>
                                <Input.Radio.Value value="starter">{(selected) => (<Button.Radio pushed={selected}><T>tenant_form_starterOffer</T></Button.Radio>)}</Input.Radio.Value>
                                <Input.Radio.Value value="standard">{(selected) => (<Button.Radio pushed={selected}><T>tenant_form_standardOffer</T></Button.Radio>)}</Input.Radio.Value>
                                <Input.Radio.Value value="expert">{(selected) => (<Button.Radio pushed={selected}><T>tenant_form_expertOffer</T></Button.Radio>)}</Input.Radio.Value>
                              </Input.Radio.Group>
                            </ReadWrite>
                          </Field.Input>
                        </Field>
                        <Field>
                          <Field.Label><T>tenant_form_usersProLimitedToSettings</T></Field.Label>
                          <Field.Input><FInput.YesNo name="usersProLimitedToSettings" default={false} /></Field.Input>
                        </Field>
                        <Display.If condition={tenant.settings.commercialOffer === "expert"}>
                          <Field>
                            <Field.Label><T>allowed_admins_issues_on_public_space</T></Field.Label>
                            <Field.Input><FInput.YesNo name="allowPublicSpaceIssue" default={true} /></Field.Input>
                          </Field>
                        </Display.If>
                        <Display.If condition={!this._isNew}>
                          <Field>
                            <Field.Label><T>tenant_form_main_user</T></Field.Label>
                            <Field.Input>
                              <Input.Selectable.One min={tenant.settings.commercialOffer === "starter" ? 1 : null} name="mainUser" model={ "User" } load={{ "tenants.tenant": true, avatar: true }} query={{ tenants: { $elemMatch: { disabled: false, tenant: this.props.id, roles: { $in: ['admin', 'manager'] }}}}} in={_in => _in ? { _id: _in } : null} out={_out => _out ? _out._id : null}>
                                <Input.Selectable.One.Render textify={(user) => user.fullname} />
                                <Input.Selectable.One.Open>
                                  {(open) => (<Button.Text onClick={open} ><T>select</T></Button.Text>)}
                                </Input.Selectable.One.Open>
                                <Input.Selectable.One.ListTitle>
                                  <span><T>category_form_manager</T></span>
                                </Input.Selectable.One.ListTitle>
                                <Input.Selectable.One.Filter>
                                  <div className="bs-role-modal-filter">
                                    <Filter.Generic name="text" buildQuery={value => ({ fullname: { '$regex': value, '$options': 'i' } })} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                                      {(value, set, clear) => (
                                        <NInput.Text value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                                          <T>freesearch</T>
                                        </NInput.Text>
                                      )}
                                    </Filter.Generic>
                                  </div>
                                </Input.Selectable.One.Filter>
                                <Input.Selectable.One.Item>
                                  <User.Item />
                                </Input.Selectable.One.Item>
                              </Input.Selectable.One>
                            </Field.Input>
                          </Field>
                        </Display.If>
                      </FieldGroup>
                    </Acl.Then>
                    <Acl.Else>
                      <Input.Hidden name="mandatoryTagsOnCategories" />
                      <Input.Hidden name="commercialOffer" />
                    </Acl.Else>
                  </Acl.If>
                  <FieldGroup title="tenant_form_workingDay" type="full">
                    <Input.NewToOld name="holidayDays">
                      <HolidayDays country={tenant.country} />
                    </Input.NewToOld>
                    <Input.NewToOld name="workingDays">
                      <WorkingDays />
                    </Input.NewToOld>
                  </FieldGroup>
                </Input.Object>
                <Acl.If resource="tenants" action="setGeolocationEngine">
                  <Display.If condition={tenant.discriminator === "entity"}>
                    <FieldGroup title="identification_code" type="full">
                      <Field>
                        <Field.Label>
                          <T>issues_numerotation_code</T>
                        </Field.Label>
                        <Field.Input><Input.Text name="issuePrefixCode" required={true} /></Field.Input>
                      </Field>
                      <Field>
                        <Field.Label><T>issues_location_engine</T></Field.Label>
                        <Field.Input>
                          <Input.Radio.Group name="locationType">
                            <Input.Radio.Value value="default">{(selected) => (<Button.Radio pushed={selected}><T>default</T></Button.Radio>)}</Input.Radio.Value>
                            <Input.Radio.Value value="spw">{(selected) => (<Button.Radio pushed={selected}>SPW</Button.Radio>)}</Input.Radio.Value>
                          </Input.Radio.Group>
                        </Field.Input>
                      </Field>
                      {tenant.locationType === "spw" ?
                        <Field>
                          <Field.Label><T>admin_resource_entity_identifier</T></Field.Label>
                          <Field.Input><Input.Text name="identifier" /></Field.Input>
                        </Field>
                        : null
                      }
                    </FieldGroup>
                  </Display.If>
                </Acl.If>
                <Input.Object name="informations">
                  <FieldGroup title="complementary_informations" type="full">
                    <Field>
                      <Field.Label><T>tenant_form_informations_siren</T></Field.Label>
                      <Field.Input>
                        <ReadWriteTextInput name="siren" info={tenant.informations.siren} />
                      </Field.Input>
                    </Field>
                    <Field>
                      <Field.Label><T>tenant_form_informations_phone</T></Field.Label>
                      <Field.Input>
                        <ReadWriteTextInput name="phone" info={tenant.informations.phone} />
                      </Field.Input>
                    </Field>
                    <Field>
                      <Field.Label>
                        <div className="bs-tenants-form-postalCode">
                          <T>tenant_form_informations_postalCode</T>
                          <Acl.If resource="tenants" action="manage">
                            <span className="fa fa-plus" onClick={ this.addPostalCode } />
                          </Acl.If>
                        </div>
                      </Field.Label>
                      <Field.Input>
                        <ReadWrite name="postalCode" info={tenant.informations.postalCode} action="manage">
                          <Input.Array name="postalCode" ref={ this._postalCode }>
                            <Input.Array.Form>
                            {(save, value, idx, cancel) => (
                              <Memoize value={value}>
                              {(value, setValue) => (
                                <div className="bs-tenants-form-postalCode">
                                  <Input.Text value={ value } onChange={ (input) => { setValue(input.value); } }/>
                                  <span onClick={() => save(value)} className="fa fa-check"/>
                                  <span onClick={ cancel } className="fa fa-remove"/>
                                </div>
                              )}
                              </Memoize>
                            )}
                            </Input.Array.Form>
                            <Input.Array.Display>
                            {(value, idx, edit, remove) => (
                              <div className="bs-tenants-form-postalCode">
                                <span>{value}</span>
                                <span onClick={edit} className="fa fa-pencil"/>
                                <span onClick={remove} className="fa fa-remove"/>
                              </div>
                            )}
                            </Input.Array.Display>
                          </Input.Array>
                        </ReadWrite>
                      </Field.Input>
                    </Field>
                    <Field>
                      <Field.Label><T>extern_web_url</T></Field.Label>
                      <Field.Input>
                        <ReadWriteTextInput name="webSiteUrls" info={tenant.informations.webSiteUrls} />
                      </Field.Input>
                    </Field>
                    <Field>
                      <Field.Label><T>with_support_of</T></Field.Label>
                      <Field.Input>
                        <ReadWrite name="tenantSponsor" info={i18n.translate(tenant.tenantSponsor)} action="manage">
                          <Input.Autocomplete.Model
                            name="tenantSponsor"
                            model={"Tenant"}
                            property={ i18n.queryProperty("name") }
                            condition={{ discriminator: "entity" }}
                          />
                        </ReadWrite>
                      </Field.Input>
                    </Field>
                  </FieldGroup>
                </Input.Object>
                <Acl.If resource="tenants" action="manageLimited">
                  <FieldGroup title="tenant_form_internalConfiguration" type="full">
                    <Field>
                      <Field.Label>
                        <T>tenant_form_clientIdJvs</T>
                      </Field.Label>
                      <Field.Input>
                        <ReadWriteTextInput name="clientIdJvs" info={ tenant.clientIdJvs }/>
                      </Field.Input>
                    </Field>
                    <Acl.If resource="tenants" action="manage">
                      <Input.Object name="connector">
                        <Field>
                          <Field.Label><T>tenant_form_connector_gp</T></Field.Label>
                          <Field.Input>
                            <Input.NewToOld name="gp" >
                              <ConnectorGp />
                            </Input.NewToOld>
                          </Field.Input>
                        </Field>
                      </Input.Object>
                    </Acl.If>
                  </FieldGroup>
                </Acl.If>
              </div>
            </FormError>
          )
        }}
      </Form.Model>
    );
  }
};

const Memoize = ({ value: _value, children }) => {
  const [value, setValue] = React.useState(_value);
  return children(value, setValue);
}

const ReadWrite = ({ name, info, action, children }) => (
  <Acl.If resource="tenants" action={ action }>
    <Acl.Then>
    { children }
    </Acl.Then>
    <Acl.Else>
        <>
          <Input.Hidden name={name} />
          <div className="bs-form-tenant-information-readonly">{info || <T>tenant_form_informations_unknown</T>}</div>
        </>
    </Acl.Else>
  </Acl.If>
);

const withReadWrite = (InputComponent) => {
  return ({ name, info, action = "manage", required = false }) => (
    <ReadWrite name={name} info={info} action={action}>
      <InputComponent name={ name } required={ required }/>
    </ReadWrite>
  );
}

const ReadWriteTextInput = withReadWrite(Input.Text);

const ConnectorGp = ({ onChange, value }) => {
  const onTypeSelected = React.useCallback((type) => {
    switch(type){
      case null: onChange(null); break;
      case "jvs_horizon":
        onChange({ 
          type: "jvs_horizon",
          idClient: "",
          entity: "",
          username: ""
        });
        break;
    }
  }, [onChange]);
  return (
    <>
      <Field>
        <Field.Label><T>tenant_form_connector_gp_type</T></Field.Label>
        <Field.Input>
          <NInput.Select value={ value?.type ? value.type : null } onChange={ onTypeSelected } fluid>
            <NInput.Select.Value value={ null }><T>tenant_form_connector_gp_type_any</T></NInput.Select.Value>
            <NInput.Select.Value value={ "jvs_horizon" }>Horizon JVS</NInput.Select.Value>
          </NInput.Select>
        </Field.Input>
      </Field>
      <Display.Switch>
        <Display.Case condition={ value?.type === "jvs_horizon" }>
          <ConnectorGpJVS value={ value } onChange={ onChange }/>
        </Display.Case>
      </Display.Switch>
    </>
  );
};
const ConnectorGpJVS = ({ value, onChange }) => {
  const onValueChange = React.useCallback((fieldName, fieldValue) => {
    onChange(Object.assign({}, value, { [fieldName]: fieldValue }));
  }, [ onChange, value ]);
  const onUsernameChange = React.useCallback(onValueChange.bind(this, "username"), [onValueChange]);
  const onIdClientChange = React.useCallback(onValueChange.bind(this, "idClient"), [onValueChange]);
  const onComponentChange = React.useCallback(onValueChange.bind(this, "component"), [onValueChange]);
  return (
    <>
      <Field>
        <Field.Label><T>tenant_form_connector_gp_jvs_client</T></Field.Label>
        <Field.Input>
          <NInput.Text value={ value.idClient } onChange={ onIdClientChange } />
        </Field.Input>
      </Field>
      <Field>
        <Field.Label><T>tenant_form_connector_gp_jvs_username</T></Field.Label>
        <Field.Input>
          <NInput.Text value={ value.username } onChange={ onUsernameChange } />
        </Field.Input>
      </Field>
      <Field>
        <Field.Label><T>tenant_form_connector_gp_jvs_component</T></Field.Label>
        <Field.Input>
          <NInput.Text value={ value.component } onChange={ onComponentChange } />
        </Field.Input>
      </Field>
    </>
  );
};
const TenantFormConnected = Application.forward(['geolocation', 'acl', 'session', 'i18n'], [['users', 'userHelper']], TenantForm, "forwardedRef");

export default TenantFormConnected;