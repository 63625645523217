import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import { Capture, EditorConfig } from "../editor";
import "./notification.css";
import User from "@universal/types/business/User";
const addNotification = (config: EditorConfig, searchHandler: (value: string) => Promise<BusinessEntity<User, { avatar: Loader }>[]>, onFound: (users: BusinessEntity<User, { avatar: Loader }>[], element: Node, select: (user: User) => void) => void, dispose: (capture: Capture) => void) => {
  if(!config.triggers) {
    config.triggers = {};
  }
  const trigger = config.triggers;
  trigger["@"] = {
    start: (capture) => {
      capture.persist();
    },
    update: (capture, ev) => {
      const text = capture.text;
      searchHandler(text.trim()).then(results => {
        if(results.length === 0 && text.length && [32, 160].indexOf(text.charCodeAt(text.length - 1)) !== -1){
          capture.release();
        }else{
          onFound(results, capture.element, (user) => {
            const dataNode = capture.createDataNode("@" + user.fullname, true, "bs-editor-notification", { type: "User", data: { _id: user._id }});

            const nodes = [
              document.createTextNode(String.fromCharCode(160)),
              dataNode
            ];
            if(capture.startIndex > 0){
              nodes.push(document.createTextNode(String.fromCharCode(160)));
            }
            capture.replace(nodes);
          });
        }
      });
    },
    dispose
  };
  return config;
};

export default addNotification;