import React, { FunctionComponent } from 'react';
import withList from './list';
import usePager from '@universal/behaviour/data/hooks/usePager';
import Comment from '@universal/types/business/Comment';
import { isEntity, Loader } from "@universal/types/technic/Entityable";
import Scrollbar from '@common/components/scrollBar';
import Editor from './editor';
import Query from '@universal/types/technic/Query';
import User from '@universal/types/business/User';
import ScrollBar from '@common/components/scrollBar/scrollBar';
import useService from '@universal/behaviour/hooks/useService';
import SessionService from '@universal/services/session';
import ApiService from '@universal/services/api';
import ObjectId from '@universal/types/technic/ObjectId';
import { Editor as EditorClass } from '@common/components/input/editor';
import SanitizeHtmlConfiguration from '@universal/types/technic/SanitizeHtmlConfiguration';
import useCombinateHandler from '@universal/hooks/useCombinateHandler';
import Display from '@universal/components/displayIf';
import Button from '@common/components/button';
import T from '@universal/behaviour/i18n';
import sanitizeHtml from 'sanitize-html';

import './chat.css';

const sanitizeConfiguration: SanitizeHtmlConfiguration<"indivisible"> = {
  allowedTags: ["div", "span", "br"],
  allowedAttributes: {
    "span": ["class", "indivisible", "data"]
  },
  nonBooleanAttributes: ["class"]
}

const List = withList(sanitizeConfiguration);

const NewMessageButton = Button.withStyle(Button.Stylized.backgroundOrangeTransparent.borderOrange.textWhite.small.withRadius);

type PrivateListProps = {
  querySearchUserForNotify: Query<User>;
  resource: string;
  id: ObjectId
  public?: boolean;
  withDisabled?: boolean;
}

type Context = {
  lastComment: Comment | null;
  scrollBottom: number | null;
  scrollTop: number | null;
  position: number | null;
};

const PrivateList: FunctionComponent<PrivateListProps> = ({ querySearchUserForNotify, resource, id, public: _public = false, withDisabled = false }) => {
  const query: { public: boolean, "subject.id": ObjectId, disabled?: boolean } = { 
    "public": _public,
    "subject.id": id
  };
  if(!withDisabled){
    query["disabled"] = false;
  }
  const {
    datas: comments,
    loadNext,
    allElementsLoaded
  } = usePager<Comment, { createdBy: Loader }>({
    model: "Comment",
    query,
    pageSize: 10,
    sort: { createdAt: -1 },
    load: { createdBy: true }
  });

  const context = React.useMemo<Context>(() => ({
    lastComment: null,
    scrollBottom: null,
    scrollTop: null,
    position: null
  }), []);

  const [newMessageArrived, setNewMessageArrived] = React.useState(false);

  const onTopLoadNext = React.useCallback((scrollBar: ScrollBar) => {
    if(!allElementsLoaded && scrollBar.position < 0.1){
      loadNext();
    }
  }, [loadNext, allElementsLoaded]);
  

  const storeScrollBottomAndTop = React.useCallback((scrollBar: ScrollBar) => {
    context.scrollBottom = scrollBar.scrollBottom;
    context.scrollTop = scrollBar.scrollTop;
    context.position = scrollBar.position;
  }, [context]);

  const onBottomDisabledNewMessageArrived = React.useCallback((scrollBar: ScrollBar) => {
    if(newMessageArrived && scrollBar.position === 1){
      setNewMessageArrived(false);
    }
  }, [newMessageArrived, setNewMessageArrived]);

  const onScroll = useCombinateHandler(
    onTopLoadNext,
    storeScrollBottomAndTop,
    onBottomDisabledNewMessageArrived
  );

  const session = useService<SessionService>("session");
  const isOwner = React.useCallback((comment: Comment) => {
    if(isEntity<Comment>(comment)){
      comment = comment.toPlainText();
    }
    return comment.createdBy === session.user._id;
  }, [session.user]);

  const [editedComment, setEditedComment] = React.useState<Comment | null>(null);

  const api = useService<ApiService>("api");
  const dropComment = React.useCallback((comment: Comment) => {
    api.service("comments", "delete").execute(comment._id);
  }, []);

  const saveComment = React.useCallback(async (message: string, datas: any[], editor: EditorClass) => {
    message = sanitizeHtml(message || "", sanitizeConfiguration).trim();
    const test = document.createElement("div");
    test.innerHTML = message;
    
    if(!test.innerText.trim()){
      return;
    }

    const notifications = datas.filter(data => data.type === "User").map(data => data.data._id);
    const comment = {
      subject: { resource, id },
      public: false,
      disabled: false,
      version: 2,
      message,
      notifications
    };
    
    if(editedComment){
      await api.service("comments", "put").execute(editedComment._id, comment);
      setEditedComment(null);
    } else {
      await api.service("comments", "post").execute(comment);
    }

    editor.clean();
  }, [editedComment]);

  const scrollbar = React.useRef<ScrollBar>(null);

  React.useEffect(() => {
    if(!scrollbar.current || comments.length === 0){
      return;
    }
    
    if(!context.lastComment){
      context.lastComment = comments[0];
      scrollbar.current.position = 1;
    }

    if(context.lastComment._id !== comments[0]._id){
      context.lastComment = comments[0];
      if(isOwner(context.lastComment) || context.position === 1){
        scrollbar.current.position = 1;
      } else if(context.scrollTop !== null){
        scrollbar.current.scrollTop = context.scrollTop;
        setNewMessageArrived(true);
      }
    } else if(context.scrollBottom !== null){
      scrollbar.current.scrollBottom = context.scrollBottom;
    }

    context.scrollBottom = scrollbar.current.scrollBottom;
    context.scrollTop = scrollbar.current.scrollTop;
  }, [comments, isOwner, setNewMessageArrived]);

  const scrollBottom = React.useCallback(() => {
    if(scrollbar.current){
      scrollbar.current.position = 1;
    }
  }, [scrollbar]);

  return (
    <div className='bs-comment-chat'>
      <div className='bs-comment-chat-list'>
        <Scrollbar onScroll={ onScroll } ref={ scrollbar }>
          <List comments={ comments }>
            <List.Action
              color='green'
              icon='fa-pencil'
              isApplicable={ isOwner }
              action={ setEditedComment }
            />
            <List.Action
              color='orange'
              icon='fa-times'
              isApplicable={ isOwner }
              action={ dropComment }
              withConfirmation
            />
          </List>
        </Scrollbar>
      </div>
      <Display.If condition={ newMessageArrived }>
        <div className='bs-comment-chat-newMessageButton'>
          <NewMessageButton onClick={ scrollBottom }>
            <T>comments_chat_newMessage</T>
          </NewMessageButton>
        </div>
      </Display.If>
      <Editor
        querySearchUserForNotify={ querySearchUserForNotify }
        onChange={ saveComment }
        sanitizeConfiguration={ sanitizeConfiguration }
        value={ editedComment ? editedComment.message : null }
      />
    </div>
  );
}

export default PrivateList;